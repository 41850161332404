.callPage {
    background-color: var(--primary);
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.callHeader {
	padding-top: 30px;
}

.callName {
	direction: rtl;
	font-weight: 500;
	font-size: 36px;
	line-height: 1.527;
	color: white;
	margin-bottom: 7px;
}

.callDescription {
	direction: rtl;
	font-weight: 400;
	font-size: 17px;
	line-height: 1.527;
	color: #F0F1F5;
}

.callImgSecondCircle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 45px 0 25px;
    border: 24px rgba(255, 255, 255, .24) solid;
}

.callImg {
	width: 202px;
    border: 24px rgba(255, 255, 255, .3) solid;
    border-radius: 50%;
}

.offImg {
	width: 72px;
}

.btnList {
	width: 341px;
	height: 137px;
	margin: 30px 0 40px;
}

.btnListRow {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    margin-bottom: 21px;
}

.callBtn {
	width: 58px;
}

.callBtnImg {
	width: 29px;
}

.callBtnText {
	font-size: 14px;
	line-height: 1.5;
	font-weight: 400;
	color: #F0F1F5;
}

.btnsDiv {
	position: absolute;
	bottom: 0;
}

@media all and (max-height: 700px) {
	.callImg {
		width: 180px;
		margin: 15px 0 15px;
	}
}

@media all and (min-height: 800px) {
	.callHeader {
		padding-top: 60px;
	}
}

@media all and (min-width: 500px) {
	.callImg {
		margin: 15px 0 15px;
	}
	.btnList {
		margin: 10px 0 30px;
	}
	.callHeader {
		padding-top: 10px
	}
}