.accountPage {
  padding: 0 20px;
  width: 100%;
  margin: 0 0 100px;
}

.accountMain {
  display: flex;
  flex-direction: row-reverse;
  text-align: right;
  padding-bottom: 15px;
}

.accountMainImgDiv {
  padding-left: 17px;
}

.accountMainImg {
  width: 100px;
  max-width: 100px;
}

.accountMainText {
  padding-left: 10px;
}

.accountMainTextH {
  padding-top: 15px;
  color: var(--primary);
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 0px !important;
  /*line-height: 35px;*/
}

.accountMainTextP {
  color: var(--secondry);
  font-size: 18px;
  line-height: 25px;
}

.accountMainTextImg {
  width: 100%;
  padding-bottom: 8px;
  padding-top: 5px;
  /*padding-left: 10px;*/
}

.accountMainTextDays {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 10px;
}

.accountMainTextDays > * {
  width: 105px;
  height: 21px;
  text-align: center;
  border-radius: 10px;
  font-size: 10px;
  line-height: 20px;
}

.daysBeen {
  background-color: var(--secondry);
  color: white;
}

.daysLeft {
  border: 1px solid #e2e2e2;
  color: #707070;
  margin-right: 20px;
}

.infoList {
  background-image: linear-gradient(to right, #fafafa, #f7f7f7, #fafafa);
  /*margin-right: 21px;*/
}

.infoSquare {
  background-color: white;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  padding: 23px 27px 24px;
  margin-bottom: 12px;
  border-radius: 5px;
}

/*.infoSquare:nth-child(1) {
	padding: 0;
	align-items: center;
}

.infoSquare:nth-child(1) .infoSquareInfo {
	color: var(--primary);
	font-size: 25px;
	font-weight: 500;
}

.infoSquare:nth-child(1) button {
	color: var(--secondry);
}

.infoSquare:nth-child(2) .infoSquareImgDiv {
	padding-top: 8px;
	padding-left: 43px;
}
*/
.infoSquareImgDiv {
  padding-left: 33px;
  display: flex;
  align-items: center;
  height: 56px;
}

.infoSquareImgDiv .infoSquareImg {
  font-size: 45px;
  fill: var(--primary);
  /*height: 46px;*/
}

/*.infoSquare:nth-child(1) img {
	width: 100px;
}
*/

/* .infoSquare:nth-child(3) img {
  width: 56px;
  height: 56px;
}

.infoSquare:nth-child(3) .infoSquareImgDiv {
  padding-left: 20px;
}

.infoSquare:nth-child(3) {
  padding-right: 23px;
}

.infoSquare:nth-child(1) .infoSquareImgDiv {
  align-items: flex-end;
}

.infoSquare:nth-child(2) .infoSquareImgDiv {
  align-items: flex-end;
} */

.infoSquareTextDiv {
  display: flex;
  flex-direction: column;
}

.infoSquareH {
  font-size: 20px;
  font-weight: 300;
  /*line-height: 29px;*/
  text-align: right;
  margin-bottom: 0px !important;
}

.infoSquareP {
  background-color: transparent;
  border: 0;
  color: #8f9bb3;
  text-align: right;
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
}

@media all and (max-width: 500px) {
  .infoSquareH {
    font-size: 16px;
  }
  .infoSquareP {
    font-size: 14px;
  }
  .infoSquareImgDiv .infoSquareImg {
    font-size: 40px;
  }
  .infoSquareImgDiv {
    padding-left: 23px;
    height: 46px;
  }
  .accountMainTextDays > * {
    width: 85px;
  }
}

.accountMain .accountMainImgDiv .roundBox {
  width: 102px;
  height: 102px;
  padding: 6px;
  background: var(--profileSecondCircle);
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  box-shadow: 0 3px 6px #00000029;
  position: relative;
  z-index: 1;
}

.accountMain .accountMainImgDiv .roundBox .innerRoundBox {
  width: 90px;
  height: 90px;
  padding: 6px;
  background: var(--profileFirstCircle);
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  z-index: 1;
}

.accountMain .accountMainImgDiv .roundBox .innerRoundBox .accountMainImg {
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.accountMain .accountMainText .progress {
  flex-direction: row-reverse;
  border-radius: 10px;
  height: 15px;
  font-size: 10px;
  font-weight: 500;
}

.accountMain .accountMainText .progress .progress-bar {
  background-color: var(--primary);
}
