.hospitalWebPages.rtl * {
  text-align: right !important;
}

.hospitalWebPages {
  padding: 6vh 7vw 0;
}

.hospitalWebPages .service_heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 0;
}

.hospitalWebPages .hospitalDepartments .hospitalDepartmentsArr {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 30px 0px 0px 0px;
  /* width: 85%; */
  /* margin-left: 7%; */
}

.hospitalWebPages
  .hospitalDepartments
  .hospitalDepartmentsArr
  .hospitalDepartmentsBoxImg {
  width: 30%;
  position: relative;
  margin: 6px;
}

.hospitalWebPages
  .hospitalDepartments
  .hospitalDepartmentsArr
  .hospitalDepartmentsBoxTitle {
  position: absolute;
  bottom: 5%;
  right: 4%;
  color: white;
  font-size: 29px;
  font-weight: 600;
}

.hospitalWebPages .webDoctorList .swipe {
  width: 85.6%;
  padding: 30px 0px 0px 0px;
  margin-left: 7%;
}

.hospitalWebPages .hospitalServicesList {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 0px 0px 0px;
  /* width: 90%; */
  /* margin-left: 3%; */
}

.hospitalWebPages .hospitalServicesList .hospitalServicesListLink {
  width: 25%;
  padding-bottom: 35px;
}

.hospitalWebPages

  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox {
  display: flex;
  justify-content: flex-end;
}

.hospitalWebPages

  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxCon
  h4 {
  font-size: 12px;
  font-weight: 400;
  color: #222b45;
}

.hospitalWebPages

  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxCon
  p {
  font-size: 10px;
  margin-bottom: 0 !important;
  font-weight: 400;
  color: #8f9bb3;
}

.hospitalWebPages

  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxCon
  span {
  color: #8f9bb3;
  font-weight: 400;
  font-size: 10px;
}

.hospitalWebPages

  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxCon
  .boxHeading {
  direction: rtl;
}

.hospitalWebPages

  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxCon
  .boxDetails
  svg {
    font-size: 16px;
    fill: var(--primary);
}

.hospitalWebPages

  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxImg {
  width: 22%;
  margin-left: 3%;
}

.hospitalWebPages .webBlogList .swipe {
  width: 85.6%;
  padding: 30px 0px 0px 0px;
  margin-left: 8%;
}

.hospitalWebPages .webBlogList .swipe .swiper-wrapper .swiper-slide {
  width: 345px !important;
  margin-right: 30px !important;
}
