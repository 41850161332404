.fitnessPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    max-width: 250px;
}

.fitnessSquareContainer {
    height: 724px;
    /*margin-top: 62px;*/
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
}

.fitnessSquare {
    height: 170px;
    /* position: relative; */
}


/* 1opt to dark the img */


/* .fitnessSquare::before {
    background: -webkit-linear-gradient(bottom, transparent, rgba(0, 0, 0, .6) 100%);
    content: "";
    position: absolute;
    height: 100px;
    width: 100%;
    border-radius: 10px;
} */

.fitnessSquareImg {
    width: 386px;
    border-radius: 10px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.fitnessSquareHeader {
    position: relative;
    left: -20px;
    top: -131px;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 0.652173913;
    font-weight: 600;
    color: var(--primary);
    text-align: right;
}