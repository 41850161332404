.hospitalsPages {
  display: flex;
  justify-content: center;
  padding: 6vh 7vw 0;
}

.webHospitalsPage {
  width: 60%;
}

.hospitalsList {
  padding: 0px 20px 0;
  margin-bottom: 80px;
}

.hospitalsList * {
  text-align: right;
}

/* FOR SEARCH SECTION TEXT BOX */
.search_sec .search_area .search_form {
  max-width: 80%;
}

.search_sec .search_area .search_form .btn_sub svg {
  fill: var(--primary);
}


.search_sec .search_area .input_ser {
  text-align: end;
}

.hospitalsList .staffSquaresDiv {
  background-image: linear-gradient(to right, #fafafa, #f7f7f7, #fafafa);
  padding: 10px 0;
  margin-top: 30px;
}

.hospitalsList .staffSquaresDiv .hospital_open_round {
  background-color: white;
  flex-direction: row-reverse;
  padding: 10px 10px 10px;
  margin-bottom: 12px;
  border-radius: 5px;
}

.searchSec .filterIcon {
  background: var(--primary);
  color: white;
  border-radius: 10px;
  padding: 18px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
