.webAboutDepartmentPage {
  padding: 6vh 7vw 0;
}

.webAboutDepartmentPage .aboutText p {
  font-size: 17px;
  direction: rtl;
}

.webAboutDepartmentPage .productVideo {
  width: 80%;
}

.webAboutDepartmentPage .videoText {
  font-size: 17px;
  text-align: center;
  padding-top: 3%;
}
