/* Create the Default Paragraphs */
.review_pages {
  padding: 0 10px 0;
  margin-bottom: 100px;
}
.review_pages * {
  text-align: right;
}
.review_pages .main_div p {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 700;
  text-transform: uppercase;
}
.review_pages .main_div p .doctor_name {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 700;
  color: var(--primary);
  text-transform: uppercase;
}


.review_pages .main_div .review_date .reviewSquare {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 23px 12px 13px 17px;
  margin-bottom: 10px;
  background-color: #fdfdfd;
  height: 93px;
  margin-top: 20px;
  border-radius: 10px;
}

.review_pages .main_div .review_date .reviewSquare .reviewSquareDateAndText {
  display: flex;
  flex-direction: row-reverse;
}

.review_pages .main_div .review_date .reviewSquareDate {
  font-size: 15px;
  width: 66px;
  margin-left: 20px;
}

.review_pages .main_div .review_date .reviewSquareDateDay {
  font-weight: 300;
  color: #222b45;
  text-align: center;
}

.review_pages .main_div .review_date .reviewSquareDateDate {
  font-weight: 600;
  color: #222b45;
  text-align: center;
}

.review_pages .main_div .review_date .reviewSquareTextDivH {
  font-size: 15px;
  line-height: 1.33;
  color: #222b45;
}

.review_pages .main_div .review_date .reviewSquareTextDivP {
  font-size: 13px;
  line-height: 1.23;
  color: #8f9bb3;
}
.review_pages .main_div .review_date .reviewSquareTextcheck {
  padding: 12px 53px 36px 0px;
}

.review_pages .main_div .reviewSquareTextcheck .reviewSquareTextCheckbox {
  -webkit-appearance: none;
  appearance: none;
  width: 23px;
  height: 23px;
  background: #edf1f7;
  border-radius: 50%;
  position: relative;
}

.review_pages
  .main_div
  .reviewSquareTextcheck
  .reviewSquareTextCheckbox:checked::after {
    content: url("../../../assets/images/vitamins/victory.png");
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    background: var(--primary);
    border-radius: 50%;
    font-size: 15px;
}

/* input[type="radio"] {
  accent-color: var(--primary);
  content: '✔';
  width: 20px;
  height: 20px;
} */

/* .review_pages .main_div .reviewSquareTextcheck [type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

.review_pages .main_div .reviewSquareTextcheck [type="checkbox"]:not(:checked)+label,
[type="checkbox"]:checked+label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.review_pages .main_div .reviewSquareTextcheck [type="checkbox"]:not(:checked)+label:before,
[type="checkbox"]:checked+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: -5px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
  border-radius: 50%;
  background-color: var(--primary);
  outline: none;
}

.review_pages .main_div .reviewSquareTextcheck [type="checkbox"]:not(:checked)+label:after,
[type="checkbox"]:checked+label:after {
    content: '✔';
    position: absolute;
    top: 0px;
    left: 3px;
    font-size: 15px;
    line-height: 0.8;
    color: #fff;
    transition: all .2s;
}

.review_pages .main_div .reviewSquareTextcheck [type="checkbox"]:not(:checked)+label:after {
  opacity: 0;
  transform: scale(0);
}

.review_pages .main_div .reviewSquareTextcheck [type="checkbox"]:checked+label:after {
  opacity: 1;
  transform: scale(1);
} */