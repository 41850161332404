.myBodyPage {
	margin-bottom: 100px;
	padding: 0 21px;
}

.myWeight {
	margin-bottom: 24px;
}

.myBodyPage .healthList {
    /*height: 340px;*/
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.healthSquare {
    background-position: center;
    background-size: cover;
    margin-bottom: 11px;
    border-radius: 10px;
}

.myBodyPage .healthSquare {
	/*height: 106px;*/
	background-image: url('../../../assets/images/myBody/water.jpg');
    background-position: center;
    background-size: cover;
}

.myBodyPage a:nth-child(1)	.healthSquare {
	background-image: url('../../../assets/images/myBody/steps.jpg');
}

.myBodyPage a:nth-child(2) .healthSquare {
	background-image: url('../../../assets/images/myBody/sleep.jpg');
}

.healthSquareContent {
	/*position: relative;*/
	/*top: -111px;*/
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
	padding: 27px 19px 25px;
	/*height: 106px;*/
}

.healthSquareContent * {
	color: white;
	text-align: right;	
}

.healthSquareText > * {
	font-size: 15px;
	line-height: 1.46;
	font-weight: 500;
}

.healthSquareNumber {
	display: flex;
}

.healthSquareNumberImg {
	width: 58px;
}

.healthSquareNumberH {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4;
    position: relative;
    left: -42px;
    top: 18px;
}

.aidCardList {
	display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    /*height: 164px;*/
}

.aidCard {
    width: 118px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.aidCardImg {
	/*width: 118px;*/
    /*height: 109px;*/
}

.aidCardHDiv {
	height: 47px;
}

.aidCardH {
	font-weight: 500;
	font-size: 18px;
	line-height: 1.16;
	color: #222B45;
	padding: 8px 16px 0;
}

@media all and (max-width: 500px) {
    .healthSquareContent {
		/*padding: 20px 19px 0;*/
    }
    .aidCard {
	    width: 90px;
		font-size: 16px;
	}

}
