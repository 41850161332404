.departmentsPage {
  padding: 0 20px;
  margin-bottom: 100px;
}

.departmentsPage * {
  text-align: right;
}

/* FOR SEARCH SECTION TEXT BOX */
.search_sec .search_area .search_form {
  max-width: 80%;
}

.search_sec .search_area .input_ser {
  text-align: end;
}

.departmentsPage .staffSquaresDiv {
  background-image: linear-gradient(to right, #fafafa, #f7f7f7, #fafafa);
  margin-top: 26px;
}

.departmentsPage .staffSquaresDiv .departments_open_round {
  background-color: white;
  flex-direction: row-reverse;
  padding: 15px 15px 15px;
  margin-bottom: 12px;
  border-radius: 5px;
}

.departmentsPage .search_sec {
  padding: 0;
}

.departmentsPage .openSquareTextH {
  font-size: 17px;
}