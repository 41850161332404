.reportSquare {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	padding: 23px 13.5px 31px 20.5px;
	margin-bottom: 10px;
}

.reportSquareTimeAndText {
	display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.reportSquareTimeAndText .procedursSquareDate {
	width: unset;
}

.procedursSquareTextDiv {
	direction: rtl;
}

.reportSquareBtn {
    width: 72px;
    height: 26px;
    font-size: 15px;
    background-color: #F4F4F4;
    border-radius: 8px;
    color: #505050;
    font-weight: 400;
}

.reportSquareBtnActive {
  background-image: var(--gradient);
  color: white;
}

.reoprtPage .SearchDiv {
	padding-bottom: 10px
}

.reoprtPage .SearchInput {
	direction: rtl;
}

