.blogPostList {
  margin-bottom: 100px;
}

.blogPostList .row-rev {
  flex-direction: row-reverse;
}

.blogPostListMain {
  padding: 19px 20px 0;
}

@media all and (max-width: 500px) {
  .blogPostList .pad {
    padding-top: 19.5px;
  }
}
