.menu {
  display: none;
  width: 355px;
  height: 100%;
  background-color: var(--primary);
  position: fixed;
  overflow-y: scroll;
  z-index: 10;
  top: 0;
  left: 57%;
  transform: translateX(-50%);
  text-align: right;
  opacity: 0.9;
}

.menuEsc {
  height: 38px;
  position: relative;
  top: 34.5px;
  right: 292px;
}

.mainList {
  padding: 40px 31px 10px 15px;
}

a {
  text-decoration: none;
}

.menuItem {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 30px;
}

.menuItemIconDiv {
  display: flex;
  flex-direction: row-reverse;
  width: 32px;
}

.menuItemIcon {
  height: 18px;
}

.menuItemHeader {
  font-size: 18px;
  line-height: 1.39;
  color: white;
  font-weight: 400;
}

/* ----------------------------------------------- CSS For Web Menu ----------------------------------------------- */
.webMenu {
  left: unset !important;
  right: 0 !important;
  transform: unset !important;
}

@media only screen and (max-width: 500px) {
  .menu {
    width: 83%;
    right: 0;
    left: unset;
    transform: unset;
  }

  .menuEsc {
    right: 80%;
  }
}