/* Create the Default Paragraphs */
.our_department_page.rtl * {
  text-align: right !important;
}

.our_department_page {
  height: 100%;
  max-width: 428px;
  width: 100%;
}

.our_department_page .ourDepartmentBanner .bannerText {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 19px;
  padding: 0 20px 0 24px;
  height: 36px;
}

.our_department_page .ourDepartmentBanner .bannerTextImg {
  height: 14px;
}

.our_department_page .ourDepartmentBanner .bannerTextH {
  font-size: 22px;
  line-height: 1.136;
  color: white;
}

.our_department_page .ourDepartmentBanner .menu_btn {
  position: absolute;
  width: 22px;
  left: -18%;
  top: 6%;
}

.our_department_page .our_department_paragraph {
  padding: 0px 10px 10px 10px;
}

.our_department_page .our_department_paragraph .our_department_p {
  margin-top: 10px;
  padding: 10px;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
}

.our_department_page .our_department_video_div .our_department_video {
  height: 100%;
  width: 100%;
}

.our_department_page .our_department_video_div .our_department_video_div_p {
  padding: 10px;
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
}

.our_department_page .our_department_video_div {
  margin-bottom: 100px;
}
