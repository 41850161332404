.product_image .productsList .productDetailSquareContainer {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px 10px;
  /* padding-bottom: 8vh; */
}

.product_image .productsList .productDetailSquareContainer .productSquareLink {
  width: 14.5%;
}

.product_image
  .productsList
  .productDetailSquareContainer
  .productSquareLink
  .productSquare {
  width: 100%;
}

.product_image
  .productsList
  .productDetailSquareContainer
  .productSquareLink
  .productSquare
  .productSquareImg {
  /*background-image: url(../../../assets/images/web/productsList/image.jpg);*/
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 100%;
  border-radius: 12px;
  position: relative;
}

.product_image
  .productsList
  .productDetailSquareContainer
  .productSquareLink
  .productSquare
  .productSquareImg
  .productSquareImgBG {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.product_image
  .productsList
  .productDetailSquareContainer
  .productSquareLink
  .productSquare
  .productSquareImg
  .productSquareImgHeart {
  position: absolute;
  top: 8%;
  left: 9%;
  width: 25%;
  height: 25%;
}

.product_image
  .productsList
  .productDetailSquareContainer
  .productSquareLink
  .productSquare
  .productSquareText {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 2vh 0.6vh;
}

.product_image
  .productsList
  .productDetailSquareContainer
  .productSquareLink
  .productSquare
  .productSquareText
  .productSquareTextH {
  font-size: 12px;
  direction: rtl;
  color: #a5a6a8;
}

.product_image
  .productsList
  .productDetailSquareContainer
  .productSquareLink
  .productSquare
  .productSquareText
  .productSquareTextPrice {
  font-size: 16px;
  color: var(--primary);
  padding-right: 5px;
}

/* dfgdffdgsdfgsdfdfsf */

.productSquareLink .productSquare .productSquareImg .time_dt {
  position: absolute;
  /*max-width: 161px;*/
  line-height: 37px;
  font-size: 12px;
  border-radius: 20px;
  right: 5px;
  bottom: 15px;
  background: #ffffff;
  /*width: 100%;*/
  text-align: center;
  font-weight: 700;
  margin: auto;
}

.productSquareLink .productSquare .productSquareImg .time_dt .off15 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.productSquareLink .productSquare .productSquareImg .time_dt p {
  margin: 0 5px;
}
.productSquareLink .productSquare .productSquareImg .time_dt.dark {
  background-color: var(--primary);
}

.productSquareLink .productSquare .productSquareImg .time_dt strong {
  margin-left: 5px;
  color: var(--primary);
}
.productSquareLink .productSquare .productSquareImg .time_dt.dark strong {
  color: #fff;
}

@media (max-width: 990px) {
  .productSquareLink .productSquare .productSquareImg .time_dt {
    font-size: 7px;   
  }
}

.productSquareImg .productSquareImgOff {
  right: 9%;
  left: unset;
}