.menuSearch {
  position: absolute;
  top: 65px;
  width: 65%;
  height: 65vh;
  border: 1px solid;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: white;
  overflow-y: scroll;
  text-align: right;
  display: none;
}

.menuSearchSection {
  display: flex;
  flex-direction: column;
}

.menuSearchSectionLink {
  width: 100%;
  height: 45px;
  background-color: var(--primary);
  color: white;
  font-weight: 600;
  font-size: 18px;
  line-height: 45px;
  padding: 0 70px;
}

.menuSearchSectionLink:hover {
  color: white;
}

.MenuSearchItemsDiv {
  padding: 11px 24px;
}

.menuSearchItem {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 5px 0;
}

.menuSearchItemText {
  padding: 0 11px;
  font-size: 18px;
  color: #222b45;
}

.menuSearchMore {
  padding: 0 70px 23px;
  color: var(--primary);
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline !important;
}

.menuSearchMore:hover {
  color: var(--primary);
}
