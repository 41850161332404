.searchWebPage {
  padding: 6vh 7vw 0;
}

.searchWebPage .searchPagesContainer {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.searchWebPage .searchPagesContainer .searchSquareLink {
  width: 20%;
  padding: 8px;
}

.searchWebPage
  .searchPagesContainer
  .searchSquareLink
  .searchSquare
  .searchSquareImg {
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 100%;
  border-radius: 12px;
  position: relative;
}

.searchWebPage
  .searchPagesContainer
  .searchSquareLink
  .searchSquare
  .searchSquareImg
  .searchSquareImgBG {
  position: absolute;
  top: 0;
  width: 95%;
  height: 95%;
}

.searchWebPage
  .searchPagesContainer
  .searchSquareLink
  .searchSquare
  .searchSquareImg
  .searchSquareImgOff {
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  border-radius: 25px;
  font-weight: 700;
  font-size: 25px;
}

@media only screen and (max-width: 1200px) {
  .searchWebPage
    .searchPagesContainer
    .searchSquareLink
    .searchSquare
    .searchSquareImg
    .searchSquareImgOff {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1000px) {
  .searchWebPage
    .searchPagesContainer
    .searchSquareLink
    .searchSquare
    .searchSquareImg
    .searchSquareImgOff {
    font-size: 15px;
  }
}

@media only screen and (max-width: 800px) {
  .searchWebPage
    .searchPagesContainer
    .searchSquareLink
    .searchSquare
    .searchSquareImg
    .searchSquareImgOff {
    font-size: 12px;
  }
}
