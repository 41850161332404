.footer {
  background-color: white;
  width: 100%;
  padding: 15px 21px;
  position: fixed;
  bottom: 0;
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  max-width: 428px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.sosAndGray {
    display: flex;
    align-items: center;
    width: 280px;
}

.sos {
  margin-right: 31px;
  color: var(--primary);
  font-weight: bold;
  border: 10px solid;
  border-radius: 50%;
  padding: 2px 4px 5px;
  font-size: 15px;
}

.grayIcons {
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.webChatImg {
  height: 22px;
  width: 22px;
}

.grayIcon {
  height: 22px;
  width: 22px;
}

.footer .mainChat {
  background: var(--primary);
  width: 79px;
  position: absolute;
  right: 19px;
  bottom: 17px;
  display: flex;
  justify-content: center;
  height: 79px;
  align-items: center;
  border-radius: 50%;
}

.footer .chat {
  width: 36px;
  height: 36px;
}

@media only screen and (max-width: 400px) {
  .footer{
    padding: 15px 10px !important;
  }
  .sos{
    margin-right: 20px;
  }
  .grayIcons{
    width: 200px !important;
  }
  .chat{
    right: 10px !important;
  }
}

@media only screen and (max-width: 380px) {
  .sos{
    margin-right: 10px;
  }
}

@media only screen and (max-width: 370px) {
  .grayIcons {
    width: 190px !important;
  }
  .footer .mainChat {
    right: 10px;
  }
}
