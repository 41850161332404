@charset "utf-8";
/* CSS Document */
/*-----------------------------------------------------------------------------

Prototype Version:  2.4

*/

@font-face {
    font-family: 'Assistant';
    src: url('../fonts/Assistant-Medium.eot');
    src: url('../fonts/Assistant-Medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Assistant-Medium.woff2') format('woff2'),
         url('../fonts/Assistant-Medium.woff') format('woff'),
         url('../fonts/Assistant-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Assistant';
    src: url('../fonts/Assistant-Light.eot');
    src: url('../fonts/Assistant-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Assistant-Light.woff2') format('woff2'),
         url('../fonts/Assistant-Light.woff') format('woff'),
         url('../fonts/Assistant-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Assistant';
    src: url('../fonts/Assistant-Regular.eot');
    src: url('../fonts/Assistant-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Assistant-Regular.woff2') format('woff2'),
         url('../fonts/Assistant-Regular.woff') format('woff'),
         url('../fonts/Assistant-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Assistant';
    src: url('../fonts/Assistant-ExtraLight.eot');
    src: url('../fonts/Assistant-ExtraLight.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Assistant-ExtraLight.woff2') format('woff2'),
         url('../fonts/Assistant-ExtraLight.woff') format('woff'),
         url('../fonts/Assistant-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Assistant';
    src: url('../fonts/Assistant-Bold.eot');
    src: url('../fonts/Assistant-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Assistant-Bold.woff2') format('woff2'),
         url('../fonts/Assistant-Bold.woff') format('woff'),
         url('../fonts/Assistant-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Assistant';
    src: url('../fonts/Assistant-SemiBold.eot');
    src: url('../fonts/Assistant-SemiBold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Assistant-SemiBold.woff2') format('woff2'),
         url('../fonts/Assistant-SemiBold.woff') format('woff'),
         url('../fonts/Assistant-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Assistant';
    src: url('../fonts/Assistant-ExtraBold.eot');
    src: url('../fonts/Assistant-ExtraBold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Assistant-ExtraBold.woff2') format('woff2'),
         url('../fonts/Assistant-ExtraBold.woff') format('woff'),
         url('../fonts/Assistant-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
} /* Table of Contents----------------------------------------------------------------------------
1. Clean Base
2. Base Typography
3. Images
4. Links
5. Forms
6. Tables
7. Framework
*/
:root{
    --primary-color:#ff69b4;
    --black-color:#000000;
    --White-color:#ffffff;
    --gray-color:#6a6a6a;
}

/* 1. Create a Clean Base
------------------------------------------------------------------------------*/

html, body, br, hr, div, span, a, object, iframe, ul, ol, dl, li, dt, dd, h1, h2, h3, h4, h5, h6, p, blockquote, q, address,
small, big, cite, dfn, ins, del, i, em, b, strong, sup, sub, strike, pre, code, samp, kbd, var, tt, form, fieldset, legend, label,input, textarea, option, .nobox 
{background: transparent; border: 0; font-size: 100%; margin: 0; outline: 0; padding: 0; vertical-align: baseline;}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {display: block}

blockquote, q {quotes: none;}
br {height: 0;}
ul, ol, dl, li {margin:0; padding:0;}
html, body{height: 100%; -webkit-appearance:none; border:none; -webkit-text-size-adjust:none;}
body{color: var(--gray-color); font-size: 18px; font-family: 'Helvetica';letter-spacing: 0.86px;}

/* Class for clearing floats */
.clear {clear:both; display:block; overflow:hidden; visibility:hidden; width:0; height:0;}
.cf:after,.cf:before{display: table;content: ''}
.cf:after{clear: both;}
*{-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}
body,*{margin: 0;padding: 0;box-sizing: border-box;font-family: 'Poppins';font-size: 20px;color: var(--gray-color)}
/* 2. Define Base Typography
------------------------------------------------------------------------------*/

/* Create the Default Headers  */
h1, h2, h3, h4, h5, h6 {margin: 0;font-weight: 700; color: var(--black-color);}
/* Specific Header Settings */
h1,.h1 {font-size: 25px;}
h2,.h2 {font-size:18px; }
h3,.h3 {font-size:40px; }
h4,.h4 {font-size:30px;}
h5,.h5 {font-size: 25px;}
h6,.h6 {font-size: 18px;}
