.vitaminsPage {
  /*margin-top: 38px;*/
  padding: 0 20.5px;
}

.vitaminsPage * {
  text-align: right;
}

.vitaminsMain {
  min-height: 724px;
}

.vitaminsTextDiv {
  padding-bottom: 26px;
}

.vitaminsTextH {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  color: #373c41;
  text-transform: uppercase;
}

.vitaminsTextP {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #373c41;
  text-transform: uppercase;
}

.vitaminsSquaresContainer {
  background-image: linear-gradient(to right, #fafafa, #f7f7f7, #fafafa);
}

.VitaminsSquare,
.vitaminsAddBtnDiv {
  display: flex;
  flex-direction: row-reverse;
  padding: 14px 20px 13px 15px;
  justify-content: space-between;
  align-items: center;
  height: 93px;
  margin-bottom: 10px;
  background-color: white;
}

.vitaminsAddBtnDiv {
  padding-left: 2px;
}

.VitaminsSquareMain {
  display: flex;
  flex-direction: row-reverse;
}

.VitaminsSquareImg {
  width: 66px;
  padding-left: 20px;
}

.VitaminsSquareHeader {
  font-size: 15px;
  line-height: 1.33;
  color: #222b45;
  padding-left: 43px;
  /*width: 265px;*/
}

.VitaminsSquareBtn {
  /* position: absolute; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* width: 23px; */
  background: var(--primary);
  border-radius: 50%;
  padding: 6px;
  /* font-size: 15px; */
}

.vitaminsAddBtnDiv svg {
  font-size: 51px;
  fill: var(--primary);
}

.page .box_btn {
  padding: 15px 0;
  box-shadow: 0px -2px 5px 0px #00000042;
  background: var(--White-color);
  text-align: center;
  position: sticky;
  width: 100%;
  bottom: 0;
  margin-top: 15px;
}
.page .box_btn .go_btn {
  max-width: 65%;
  width: 100%;
  margin: 0 auto;
  color: var(--White-color);
  background: var(--primary);
  border-radius: 10px;
  display: inline-block;
  padding: 8px 0;
  font-size: 17px;
  font-weight: 700;
  height: 40px;
}

.page.rtl .box_btn * {
  text-align: center !important;
}

.page .box_btn .container{max-width: 767px; margin:0 auto; padding:0 15px; width:100%;}