.notifications {
  background-image: linear-gradient(to right, #FAFAFA, #F7F7F7, #FAFAFA);
  padding-bottom: 2px;
  margin-bottom: 100px;
}

.notifications {
  text-align: right;
}

.NotificationsSquare {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 12px;
  background-color: white;
  padding: 23px 21px 21px 21px;
  max-width: 100%;
  /*height: 109px;*/
  position: relative;
}

.NotificationsImgDiv {
  /*width: 63px;*/
}

.NotificationsImg {
  border-radius: 50%;
  width: 64px;
  height: 64px;
  margin-left: 10px;
  max-width: max-content;
  object-fit: cover;
  /*width: 100%;*/
  /*height: 100%;*/
}

.NotificationsTextDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  max-width: 82%;
  width: 82%;
}

.NotificationsTextMassage {
  font-size: 15px;
  font-weight: 300;
  line-height: 1;
  direction: rtl;
}

.NotificationsDateAndBtn {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.NotificationsTextDate {
  color: #9BA1A8;
  font-size: 10px;
  direction: rtl;
}

.NotificationsBtn {
  height: 26px;
  width: 72px;
  /* text-align: center !important; */
  display: flex;
  justify-content: center;
  background: var(--gradient);
  color: white;
  line-height: 26px;
  border-radius: 10px;
  font-size: 13px;
}

.NotificationsTextMassageSpan {
  color: var(--primary);
  font-weight: 500;
}