.doctorsSearchPages {
  padding: 6vh 7vw 0;
}

.doctorsSearchPages .searchPagesContainer .searchPagesContainerBtn {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  color: #222b45;
  font-size: 18px;
  font-weight: 400;
  width: 100%;
}

.doctorsSearchPages .doctorListMain {
  width: 85%;
  margin: auto;
}

.doctorsSearchPages .doctorListContainer {
  justify-content: center;
}

.doctorsSearchPages .doctorListContainer .doctorDetail {
  background-color: #f4f4f4;
}

.doctorsSearchPages .doctorDetail .doctorDetailBtn {
  font-size: 18px;
  font-weight: 400;
  background-color: var(--primary);
  border-color: var(--primary);
  width: 30%;
}

.doctorsSearchPages
  .doctorDetail
  .doctorDetailBtn
  .row
  .doctorDetailArrowIcon
  svg {
  height: 18px;
  width: 18px;
  position: absolute;
  margin-top: 5px;
}

.doctorsSearchPages
  .doctorDetail
  .doctorDetailBtn
  .row
  .doctorDetailArrowIcon
  svg
  > path {
  color: white;
}

.doctorsSearchPages .doctorDetail .doctorDetailBtn .row .doctorDetailBtnText {
  color: white;
}

.doctorsSearchPages .doctorDetail .doctorDetailImg {
  height: 35px;
  width: 35px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 5px;
}

.doctorsSearchPages .doctorDetail .doctorDetailTitle {
  font-size: 18px;
  font-weight: 400;
  color: #222b45;
  margin: 0 auto;
  margin-right: 10px;
}

.doctorsSearchPages .doctorSearchPagesMoreBtnDiv .doctorSearchPagesMoreBtn {
  background-color: var(--primary);
  border-color: var(--primary);
  color: white;
  width: 20%;
  border-radius: 0px;
  direction: rtl;
}

.doctorsSearchPages
  .doctorSearchPagesMoreBtnDiv
  .doctorSearchPagesMoreBtn:hover,
.doctorsSearchPages
  .doctorSearchPagesMoreBtnDiv
  .doctorSearchPagesMoreBtn:active {
  background-color: var(--primary);
}

.doctorsSearchPages button:focus,
.doctorsSearchPages button:active {
  outline: none !important;
  box-shadow: none !important;
}

.doctorsSearchPages
  .searchPagesContainer
  .row
  .dropdown
  .dropdown-menu
  a:active {
  background-color: var(--primary);
  color: white;
}