.searchAllWebPages {
  padding: 6vh 7vw 0;
}

.searchAllWebPages .searchAllSection {
  display: flex;
  flex-direction: column;
}

.searchAllWebPages .searchAllSection .searchAllSectionLink {
  width: 100%;
  height: 45px;
  background-color: var(--primary);
  color: white;
  font-weight: 600;
  font-size: 18px;
  line-height: 45px;
  padding: 0 5px;
  text-align: right !important;
}

.searchAllWebPages .searchAllSection .searchAllSectionLink:hover {
  color: white;
}

.searchAllWebPages .searchAllSection .searchAllItemsDiv {
  padding: 10px 0px;
}

.searchAllWebPages .searchAllSection .searchAllItemsDiv .searchAllItem {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
  margin: 10px;
}

.searchAllWebPages
  .searchAllSection
  .searchAllItemsDiv
  .searchAllItem
  .searchAllItemImg {
  height: 35px;
  width: 35px;
  object-fit: cover;
  border-radius: 50%;
  margin: 8px 10px 8px  0;
}

.searchAllWebPages
  .searchAllSection
  .searchAllItemsDiv
  .searchAllItem
  .searchAllItemText {
  font-size: 18px;
  font-weight: 400;
  margin: 0 auto;
  margin-right: 10px;
  color: #222b45;
  direction: rtl;
}

.searchAllWebPages
  .searchAllSection
  .searchAllItemsDiv
  .searchAllItem
  .searchAllItemBtn {
  width: 30%;
  background-color: var(--primary);
  border-color: var(--primary);
  font-size: 18px;
  font-weight: 400;
}

.searchAllWebPages
  .searchAllSection
  .searchAllItemsDiv
  .searchAllItem
  .searchAllItemBtn
  .row
  .SearchAllArrowIcon
  svg {
  height: 18px;
  width: 18px;
  position: absolute;
  margin-top: 5px;
}

.searchAllWebPages
  .searchAllSection
  .searchAllItemsDiv
  .searchAllItem
  .searchAllItemBtn
  .row
  .SearchAllArrowIcon
  svg
  > path {
  color: white;
}

.searchAllWebPages
  .searchAllSection
  .searchAllItemsDiv
  .searchAllItem
  .searchAllItemBtn
  .row
  .searchAllItemBtnText {
  color: white;
}

.searchAllWebPages button:focus,
.searchAllWebPages button:active {
  outline: none !important;
  box-shadow: none !important;
}