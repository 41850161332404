/* Create the Default Paragraphs */
.club.rtl * {
    text-align: right !important;
}

.club {
    height: 100%;
    max-width: 428px;
    width: 100%;
}

.club p {
    margin-bottom: 20px;
}

/* Other Typo */
.club strong {
    font-weight: bold;
}

.club hr {
    border: 0 #000 solid;
    border-top-width: 1px;
    clear: both;
    height: 0;
}

.club ol {
    list-style: decimal;
}

.club ul {
    list-style: disc;
}

.club .unlisted {
    list-style: none;
}

/* 3. Images
------------------------------------------------------------------------------*/

/* Remove border around linked images */
.club img {
    border: 0;
    border-style: none;
    max-width: 100%;
}

/* 4. Links
------------------------------------------------------------------------------*/

/* Default Link Types */
.club a {
    color: var(--pink-color);
    text-decoration: none;
}

.club a:hover {
    color: var(--secondar-color);
}

.club a:active {
    color: #e8941a;
}

.club a:focus {
    outline: none;
}

.club a.current {
    color: #e8941a;
}

.club input,
textarea {
    font-family: 'Assistant';
}

/* 5. Forms
------------------------------------------------------------------------------*/

.club input[type='submit'] {
    -webkit-appearance: none;
    border: none;
    -webkit-text-size-adjust: none;
}

.club select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
}

.club textarea {
    overflow: auto;
}

.club input:focus::placeholder {
    font-size: 0;
}

.club ::-webkit-input-placeholder {
    color: #000;
}

.club :-moz-placeholder {
    color: #000;
}

.club ::-moz-placeholder {
    color: #000;
}

.club :-ms-input-placeholder {
    color: #000;
}

.club ::-ms-input-placeholder {
    color: #000;
}

.club :placeholder-shown {
    color: #000;
}

/* Removes fieldset borders. even on Opea 7 */
.club fieldset {
    border: 1px solid transparent;
}

/* 6. Tables
------------------------------------------------------------------------------*/

/* Table Fixes */
.club table {
    border-spacing: 0;
    border-collapse: collapse;
}

.club td {
    text-align: right;
    font-weight: normal;
}


/* 7. Framework ------------------------------------------------------------------------------*/
/* Common CSS */
/*transition effect*/
.club input,
textarea,
button,
select {
    font-family: 'Helvetica';
}

.club button {
    border: none;
    outline: none;
    background: none;
}

.club a,
.panner_box_inner .pannel_box,
a::after,
a::before,
.quick_area li,
#btnmenu span::after,
#btnmenu span::before,
#btnmenu span {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

/* Page Layout */
.club .container {
    max-width: 767px;
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
}

.club .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.club .col-1,
.club .col-2,
.club .col-3,
.club .col-4,
.club .col-5,
.club .col-6,
.club .col-7,
.club .col-8,
.club .col-9,
.club .col-10,
.club .col-11,
.club .col-12 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.club .col-1 {
    max-width: 8.33333333%;
}

.club .col-2 {
    max-width: 16.66666667%;
}

.club .col-3 {
    max-width: 25%;
}

.club .col-4 {
    max-width: 33.33333333%;
}

.club .col-5 {
    max-width: 41.66666667%;
}

.club .col-6 {
    max-width: 50%;
}

.club .col-7 {
    max-width: 58.33333333%;
}

.club .col-8 {
    max-width: 66.66666667%;
}

.club .col-9 {
    max-width: 75%;
}

.club .col-10 {
    max-width: 83.33333333%;
}

.club .col-11 {
    max-width: 91.66666667%;
}

.club .col-12 {
    max-width: 100%;
}


.club .d-flex {
    display: flex;
}

.club .flex-wrap {
    flex-wrap: wrap;
}

.club .align-center {
    align-items: center;
}

.club .align-start {
    align-items: flex-start;
}

.club .align-end {
    align-items: flex-end;
}

.club .justify-center {
    justify-content: center;
}

.club .justify-start {
    justify-content: flex-start;
}

.club .justify-end {
    justify-content: flex-end;
}

.club .justify-between {
    justify-content: space-between;
}


/* Header Area */
.club .header_area {
    padding: 25px 0;
}

.club .header_area .btn {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    box-shadow: 0 0 10px #00000012;
    display: flex;
    align-items: center;
    justify-content: center;
}

.club .header_area .btn img {
    max-width: 17px;
}

/* Banner Section */
.club .banner {
    width: 100%;
    height: 280px;
    background: url('../../../assets/images/Dentistry_Pharmacy_Building.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 60px;
}

.club .banner img {
    position: absolute;
}

.club .banner::after {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: #0000004f;
}

.club .banner .menu_btn {
    top: 15px;
    right: 50px;
    z-index: 1;
    width: 26px;
}

.club .banner_content {
    position: relative;
    z-index: 1;
    padding: 0 0 15px 10px;
}

.club .banner_content h1 {
    color: var(--White-color);
    font-weight: 600;
    font-size: 25px;
    font: medium;
    width: 100%;
    padding-right: 10px;
}

.club .banner_content h6 {
    color: var(--White-color);
    font-size: 15px;
    font-weight: 400;
    font: medium;
    padding-right: 10px;
}

/* Button */
.club .box_btn {
    padding: 15px 0;
    box-shadow: 0px -2px 5px 0px #00000042;
    background: var(--White-color);
    text-align: center;
    position: sticky;
    width: 100%;
    bottom: 0;
    margin-top: 15px;
}

.club .box_btn .go_btn {
    max-width: 65%;
    width: 100%;
    margin: 0 auto;
    color: var(--White-color);
    background: var(--primary);
    border-radius: 10px;
    display: inline-block;
    padding: 8px 0;
    font-size: 17px;
    font-weight: 700;
    height: 40px;
}

.club.rtl .box_btn * {
    text-align: center !important;
}

.main {
    padding-top: 102px;
}

.club .service_sec {
    padding: 25px 0 15px;
}

.club .service_sec .sec_heading_area {
    padding: 0px 25px 0px 15px;
}

.club .service_sec .sec_heading_area a {
    color: var(--primary);
}

.club .service_sec .sec_heading_area h3 {
    color: var(--black-color);
}

.club .service_sec .sec_heading_area a img {
    margin-right: 10px;
}

.club .service_sec .service_slider {
    padding: 15px 0 0;
}

.club .service_sec .service_slider .slide_box {
    width: 163px !important;
    height: 182px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background: #ffffff;
    border-radius: 10px;
    -webkit-box-shadow: 0 2px 12px #00000017;
    box-shadow: 0 2px 12px #00000017;
    position: relative;
    overflow: hidden;
    margin: 0 7px 15px;
    z-index: 1;
}

.club .service_sec .service_slider .slide_box::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 86px;
    top: 0;
    left: 0;
    background: var(--primary);
}

.club .service_sec .service_slider .slide_box .box_img {
    width: 101px;
    height: 101px;
    padding: 6px;
    background: #ffffff;
    border-radius: 100%;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-shadow: 0 3px 6px #00000029;
    box-shadow: 0 3px 6px #00000029;
    position: relative;
    z-index: 1;
}

.club .service_sec .service_slider .slide_box .box_img img {
    border-radius: 100%;
}

.club .service_sec .service_slider .slide_box h6 {
    color: #222B45;
    margin-top: 10px;
}

.club .service_sec .service_slider .slick-arrow {
    display: none !important;
}

.club .service_list_sec {
    padding: 15px 20px;
}

.club .service_list_sec #tabs-nav {
    /* -ms-flex-wrap: wrap;
        flex-wrap: wrap; */
}

.club .service_list_sec #tabs-nav li a {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    color: #A5A6A8;
    padding: 6px 12px;
    border-radius: 50px;
    margin-bottom: 10px;
    display: inline-block;
}

.club .service_list_sec #tabs-nav li.active a {
    background: var(--primary);
    color: #ffffff;
}

.club .service_list_sec #tabs-content .list_box_area {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.club .service_list_sec #tabs-content .list_box_area .list_box {
    /* max-width: 180px; */
    width: 100%;
    /* margin: 0 5px 30px; */
    position: relative;
    padding-right: 5px;
    padding-left: 5px;
}

.club .service_list_sec #tabs-content .list_box_area .list_box .box_img {
    height: 180px;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 13px;
    position: relative;
}

.club .service_list_sec #tabs-content .list_box_area .list_box .box_img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.club .service_list_sec #tabs-content .list_box_area .list_box .box_img span {
    text-align: center !important;
}

.club .service_list_sec #tabs-content .list_box_area .list_box .box_img .discount_dt {
    position: absolute;
    left: 16px;
    bottom: 15px;
    font-size: 15px;
    color: #ffffff;
    background: var(--primary);
    width: 63px;
    height: 35px;
    border-radius: 20px;
    text-align: center;
    line-height: 35px;
    font-weight: 600;
}

.club .service_list_sec #tabs-content .list_box_area .list_box .box_img .time_dt {
    position: absolute;
    max-width: 161px;
    height: 37px;
    line-height: 37px;
    font-size: 15px;
    border-radius: 20px;
    left: 16px;
    bottom: 15px;
    background: #ffffff;
    width: 100%;
    text-align: center;
    font-weight: 700;
}

.club .service_list_sec #tabs-content .list_box_area .list_box .box_img .time_dt strong {
    color: var(--primary);
    padding-right: 10px;
}

.club .service_list_sec #tabs-content .list_box_area .list_box .box_detail {
    padding: 0 5px;
}

.club .service_list_sec #tabs-content .list_box_area .list_box .box_detail span{
    text-align: left !important;
}

.club .service_list_sec #tabs-content .list_box_area .list_box .box_detail h6 {
    font-size: 13px;
    color: #A5A6A8;
    font-weight: normal;
}

.club .service_list_sec #tabs-content .list_box_area .list_box .box_detail span {
    color: var(--primary);
    font-weight: 600;
}

.club .service_list_sec #tabs-content .list_box_area .list_box .wish_list_icon {
    position: absolute;
    top: 12px;
    left: 15px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background: #0000001A;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.club .category_list_sec {
    padding: 0 20px;
}

.club .category_list_sec h3 {
    text-transform: uppercase;
    margin-bottom: 20px;
}

.club .category_list_sec .category_list .list_box {
    margin-bottom: 0px;
}

.club .category_list_sec .category_list .list_box .box_img {
    position: relative;
}

.club .category_list_sec .category_list .list_box .box_img .club_category_img {
    border-radius: 10px;
}

.club .category_list_sec .category_list .list_box .box_img h6 {
    position: absolute;
    right: 8px;
    bottom: 8px;
    text-transform: none;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 500;
}

@media only screen and (max-width: 427px) {
    .search_area .search_form {
        max-width: 180px;
    }

    footer ul li {
        padding-left: 12px;
    }

    #togglee_menu ul li {
        margin-bottom: 15px;
    }

    #togglee_menu ul li a {
        font-size: 15px;
    }

    .service_list_sec #tabs-content .list_box_area {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
}

.club .list-unstyled {
    padding-right: 0;
    list-style: none;
}

.search_form {
    display: flex !important;
    flex-direction: row-reverse;
    align-items: center;
    border: 1px solid #EAEBEC;
    border-radius: 12px;
    width: 320px;
    padding: 0 15px;
  }
  .category_list_sec h3{
      font-size: 17px;
      font-weight: 500;
  }
  .service_list_sec{
      direction: rtl;
  }
  .service_list_sec h6{
      font-weight: 500;
  }

  .club .service_list_sec{
      margin-bottom: 100px;
  }

.club .search_sec .search_area .search_form {
  max-width: 72%;
}
@media all and (max-width: 400px) {
  .club .search_sec .search_area .search_form {
    max-width: 68%;
  } 
}