.departments_open_round {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 13.5px 10px 13.5px 0px;
}

.departments_open_round .imgAndText {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.departments_open_round .imgAndText .openRoundImg {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    border-radius: 15%;
    object-fit: cover;
}

.departments_open_round .imgAndText .openRoundText {
  max-width: 100%;
}

.departments_open_round .imgAndText .openRoundText .openRoundTextH {
  font-size: 17px;
  line-height: 1.33;
  color: #222b45;
  font-weight: 300;
}

.departments_open_round .imgAndText .openRoundText .openRoundTextP {
  font-size: 13px;
  line-height: 1.23;
  color: #8f9bb3;
}

.departments_open_round .departmentsbtn {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 12px;
}

.departments_open_round .departmentsbtn a {
  text-align: center;
}

.departments_open_round .departmentsbtn .choose .openRoundBtn {
	width: 72px;
	height: 26px;
}

.departmentsbtn > * {
  width: 72px;
  height: 26px;
}
