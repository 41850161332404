/* Service box */
.service_list_sec{padding: 20px 0 10px;}
.service_list_sec .container{max-width: 767px; margin:0 auto; padding:0 15px; width:100%;}
.service_box_area{padding-top: 10px;}
.service_box_area .service_box{display: flex;align-items: flex-start;width: 100%;margin-bottom: 3px; justify-content: space-between;}
.service_box_area .service_box:last-child  .box_con{border: none;}
.service_box .box_img{max-width: 66px;height: 66px;border-radius: 5px;width: 100%;overflow: hidden;}
.service_box .box_img img{width: 100%;height: 100%;object-fit: cover;}
.service_box .box_con{margin-right: 10px;width: calc(100% - 80px);padding:8px 0 20px;border-bottom: 1px solid #ebebeb;}
.box_con h4{font-size: 15px;font-weight: 300;color:#222B45}
.box_con p{font-size: 13px;margin: 0;font-weight: 400;color:#8F9BB3; line-height: 1;}
.box_con span{font-size: 16px;color: #BF2C7D;font-weight: 600; margin-top: 3px;}


.service_list_sec .justify-between{justify-content: space-between;}
.service_list_sec .row-reverse{flex-direction: row-reverse;}
.service_list_sec .service_heading h3{font-size: 17px;font-weight: 400; margin-bottom: 8px;}
.service_list_sec .service_heading a{font-size: 15px;color: var(--primary);font-weight: 400; direction: ltr;}
.service_list_sec .service_heading a img{margin-right: 3px;transition: all .5s ease;max-width: 11px;} 
.service_list_sec .d-flex{display: flex;}
 
.service_list_sec .box_heading a {display: flex; align-items: flex-start; padding-top: 3px}
.service_list_sec .box_heading h4 {margin-bottom: 13px;}