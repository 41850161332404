.treatmentsSearchPages {
  padding: 6vh 7vw 0;
}

.treatmentsSearchPages .searchPagesContainer .searchPagesContainerBtn {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  color: #222b45;
  font-size: 18px;
  font-weight: 400;
  width: 100%;
}

.treatmentsSearchPages .treatmentListMain {
  width: 85%;
  margin: auto;
}

.treatmentsSearchPages .treatmentListContainer .treatmentDetail {
  background-color: #f4f4f4;
}

.treatmentsSearchPages .treatmentDetail .treatmentDetailBtn {
  font-size: 18px;
  font-weight: 400;
  background-color: var(--primary);
  border-color: var(--primary);
  width: 30%;
}

.treatmentsSearchPages
  .treatmentDetail
  .treatmentDetailBtn
  .row
  .treatmentDetailArrowIcon
  svg {
  height: 18px;
  width: 18px;
  position: absolute;
  margin-top: 5px;
}

.treatmentsSearchPages
  .treatmentDetail
  .treatmentDetailBtn
  .row
  .treatmentDetailArrowIcon
  svg
  > path {
  color: white;
}

.treatmentsSearchPages
  .treatmentDetail
  .treatmentDetailBtn
  .row
  .treatmentDetailBtnText {
  color: white;
}

.treatmentsSearchPages .treatmentDetail .treatmentDetailTitle {
  font-size: 18px;
  font-weight: 400;
  color: #222b45;
  margin: 0 auto;
  margin-right: 10px;
}

.treatmentsSearchPages
  .treatmentSearchPagesMoreBtnDiv
  .treatmentSearchPagesMoreBtn {
  background-color: var(--primary);
  border-color: var(--primary);
  color: white;
  width: 20%;
  border-radius: 0px;
  direction: rtl;
}

.treatmentsSearchPages
  .treatmentSearchPagesMoreBtnDiv
  .treatmentSearchPagesMoreBtn:hover,
.treatmentsSearchPages
  .treatmentSearchPagesMoreBtnDiv
  .treatmentSearchPagesMoreBtn:active {
  background-color: var(--primary);
}

.treatmentsSearchPages button:focus,
.treatmentsSearchPages button:active {
  outline: none !important;
  box-shadow: none !important;
}

.treatmentsSearchPages
  .searchPagesContainer
  .row
  .dropdown
  .dropdown-menu
  a:active {
  background-color: var(--primary);
  color: white;
}
