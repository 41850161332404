.openSquare {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 13.5px 20px 13.5px 13.5px;
    background-color: white;
    margin-bottom: 10px;
}

.imgAndText {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.openSquareImg {
	width: 66px;
	height: 66px;
	margin-left: 20px;
}

.openSquareText {
	width: 203px;
}

.openSquareTextH {
	font-size: 15px;
	line-height: 1.33;
	color: #222B45;
}

.openSquareTextP {
	font-size: 13px;
	line-height: 1.23;
	color: #8F9BB3;
}

.openSquareBtn {
    width: 72px;
    height: 26px;
    color: white;
    background: var(--gradient);
    text-align: center !important;
    border-radius: 15px;
    font-size: 13px;
    line-height: 26px;
}
