.gynecologybanner {
  position: relative;
}

.bannerText {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 19px;
  padding: 0 20px 0 24px;
  height: 36px;
}

.bannerTextImg {
  height: 14px;
  margin-top: 10px;
}

.bannerTextH {
  font-size: 22px;
  line-height: 1.136;
  color: white;
}
