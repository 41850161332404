.myBaby * {
  text-align: right;
}

.myBaby {
  /*padding-top: 38px;*/
}

.hospitalAndUserSections {
  padding: 0 21px;
  margin-bottom: 100px;
}

.customizer .customizer-content .hospitalAndUserSections{
  margin-bottom: 0px !important;
}

.mybabySection,
.userSection {
  display: flex;
  flex-direction: row-reverse;
}

.mybabySectionMain {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
}

.mybabySectionMain1 {
  margin-bottom: 32px;
}

.MyBabyDate {
  display: flex;
  flex-direction: column;
  color: var(--primary);
  text-align: center;
  padding-left: 23px;
  margin-right: 7px;
  width: 57.3px;
}

.MyBabyDateDay {
  color: var(--primary);
  font-size: 25px;
  font-weight: 500;
  line-height: 1.1;
  text-align: center;
}

.MyBabyDateMonth {
  color: var(--primary);
  text-transform: capitalize;
  font-size: 22px;
  line-height: 1.1;
  margin-bottom: 17px;
  text-align: center;
}

.MyBabyDateLine {
  height: -webkit-fill-available;
  text-align: left;
  border-right: 2px solid var(--primary);
  width: 50%;
  color: white;
  opacity: 30%;
  margin-bottom: 4.7px;
}

.myBaby .mybabySectionMain1 .roundBox {
  width: 102px;
  height: 102px;
  padding: 6px;
  background: var(--profileSecondCircle);
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  box-shadow: 0 3px 6px #00000029;
  position: relative;
  z-index: 1;
  margin-left: 40px;
}

.myBaby .mybabySectionMain1 .roundBox .innerRoundBox {
  width: 90px;
  height: 90px;
  padding: 6px;
  background: var(--profileFirstCircle);
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  z-index: 1;
}

.myBaby .mybabySectionMain1 .roundBox .innerRoundBox img {
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
  height: auto;
}

/* .mybabySection1Img {
  height: 100px;
  border-radius: 50%;
  margin-left: 40px;
} */

.mybabySection1Text {
  color: var(--primary);
}

.mybabySection1TextHeader {
  font-weight: 500;
  font-size: 25px;
  line-height: 1.4;
}

.mybabySection1TextP {
  font-size: 18px;
  color: var(--secondry);
  line-height: 1.38;
}

.mybabySection1TextBtn {
  background-color: var(--secondry);
  width: 105px;
  height: 21px;
  border: 0;
  border-radius: 15px;
  color: white;
  font-size: 10px;
  margin-top: 6px;
  text-align: center;
}

.mybabySection2MainImg {
  height: 264px;
  width: 158px;
  border-radius: 10px;
  margin-left: 15px;
}

.mybabySection2SubImages {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mybabySection2SubImages > * {
  height: auto;
  width: 153px;
  border-radius: 10px;
}

.mybabySection3 {
  height: 121px;
}

.mybabySection3Img {
  height: 100px;
  margin-left: 17px;
}

.mybabySection3Text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100px;
  padding: 3px 0 7px;
}

.mybabySection3TextHeader {
  font-weight: 400;
  font-size: 22px;
  line-height: 1;
  padding: 8px 0 3px 0;
  margin: 0;
}

.mybabySection3TextP {
  text-transform: capitalize;
  font-size: 15px;
  opacity: 50%;
  line-height: 1.2;
  font-family: system-ui;
}

.mybabySection3TextTime {
  font-size: 10px;
  opacity: 50%;
  line-height: 1.6;
  padding-bottom: 8px;
}

.mybabySection3TextGrades {
  display: flex;
  flex-direction: row-reverse;
  font-size: 12px;
}

.mybabySection3TextGrades > div {
  margin-right: 10px;
  /*opacity: 50%;*/
  font-size: 13px;
  line-height: 1.23;
  font-family: system-ui;
}

.mybabySection3TextGradesScore {
  color: #8f9bb3;
}

.mybabySection3TextGradesReviews {
  color: #c5cee0;
}

.star {
  color: var(--primary);
}

.myBaby {
  overflow-y: scroll;
  margin-top: 11px;
}

.myBaby::-webkit-scrollbar {
  width: 0;
}

.mybabySectionMain4 {
  background-image: url("meeting.jpg");
  display: flex;
  flex-direction: row-reverse;
  height: 160px;
  border-radius: 10px;
  background-size: cover;
  align-items: flex-end;
}

.mybabySection4Btn {
  height: 14px;
  position: relative;
  bottom: 22px;
}

.mybabySectionMain4,
.mybabySectionMain2 {
  justify-content: space-between;
  width: -webkit-fill-available;
}

.mybabySection4Header {
  width: 210px;
  font-size: 25px;
  font-weight: 500;
  line-height: 1.2;
  color: white;
}

.mybabySectionMain4 {
  padding: 90px 21px 4px 17.5px;
  color: white;
}

.fixedBtns {
  display: flex;
  flex-direction: row-reverse;
  align-items: end;
  position: fixed;
  top: 96%;
  left: -1.4%;
  right: 0%;
  border-radius: 15px;
  height: 0%;
  width: 100%;
  color: var(--primary);
}

.white {
  background-color: white;
  width: -moz-fit-content;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* .plusLink {
     flex-direction: column;
    align-items: center;
    position: fixed;
    top: 36%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    height: 300px;
    width: 400px;
    color: var(--primary);
} */

.plusExplain {
  /*position: relative;*/
  /*bottom: -94%;*/
  /*left: 41%;*/
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid;
  width: fit-content;
  font-size: 11px;
  color: black;
  padding: 1px 5px;
  color: transparent;
  margin-bottom: 3px;
}

.plusLink .plus {
  /*position: relative;*/
  /*bottom: -116%;*/
  /*right: 1%;*/
  background-color: white;
  border-radius: 50%;
  border: 3px solid var(--primary);
  /* padding: 5px; */
  font-size: 40px;
  fill: var(--primary);
}

.white .share {
  /* position: relative; */
  /* bottom: -114%; */
  /* right: -44%; */
  font-size: 25px;
  color: var(--primary);
  background-color: white;
  border-radius: 50%;
  border: 3px solid var(--primary);
  padding: 7px;
  font-size: 40px;
  fill: var(--primary);
}

.addContent,
.addDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  border-radius: 15px;
  height: 300px;
  width: 400px;
  background-color: white;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  color: var(--primary);
  border: 2px solid var(--primary);
}

/* .addContent {
  display: flex;
} */

/* .userSection {
  margin-bottom: 20px;
} */

.userSectionMain {
  position: relative;
  margin-bottom: 20px;
  width: -webkit-fill-available;
}

.userSectionImgDiv {
  position: relative;
}

.userSectionImgDiv:before {
  content: "";
  background: -webkit-linear-gradient(
    bottom,
    transparent,
    rgba(0, 0, 0, 0.9) 200%
  );
  position: absolute;
  width: 99%;
  height: 100%;
  border-radius: 10px;
}

.userSectionImg {
  /*position: relative;*/
  width: 343px;
  border-radius: 10px;
}

.userSectionH {
  position: absolute;
  top: 10px;
  right: 21px;
  font-size: 25px;
  font-weight: 500;
  line-height: 1.2;
  color: white;
  direction: rtl;
  padding-left: 10px;
  width: 85%;
}

.userSectionP {
  position: absolute;
  /*top: 40px;*/
  /*right: 21px;*/
  color: white;
  direction: rtl;
  padding-left: 10px;
}

/*.userSectionImgDiv {
    position: relative;
  width: 120px;
  height: 120px;
}

.userSectionImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
}*/

/*.userSectionImgDiv:before {
  content: '';
  background: -webkit-linear-gradient(top,  transparent, rgba(0,0,0,0.9) 400%);
  position: absolute;
  width: 100%;
  height: 100%; 
}*/

/*.userSectionH {
    position: absolute;
    bottom: 0;
    right: 0px;
    color: white;
    font-size: 13px;
    padding: 0px 5px 6px 0;
}*/
@media all and (max-width: 500px) {
  .userSectionP {
    font-size: 16px;
  }
  .userSectionH, .mybabySection4Header, .MyBabyDateDay {
    font-size: 21px;
  }
  .mybabySection3TextHeader, .MyBabyDateMonth {
    font-size: 18px;
  }
  .mybabySection3TextP {
    font-size: 12px;
  }
  .mybabySection3TextGrades > div {
    font-size: 11px;
    margin-right: 5px;
  }
  .myBaby .mybabySectionMain1 .roundBox {
    margin-left: 25px;
  }
  .fixedBtns {
    top: 84%;
    left: -2.3%;
  }
}
/* 
@media all and (min-width: 500px) {
  .fixedBtns {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 36%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    height: 300px;
    width: 390px;
    color: var(--primary);
  }
  .white {
    position: relative;
    bottom: -101%;
    left: 43%;
    background-color: white;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 11px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .plusExplain {
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid;
    width: fit-content;
    font-size: 11px;
    color: black;
    padding: 1px 5px;
    color: transparent;
    margin-bottom: 3px;
  }
  .plus {
    background-color: white; 
    border-radius: 50%; 
    border: 1px solid var(--primary); 
    padding: 5px; 
    width: 40px; 
  }
  .share {
    font-size: 25px;
    color: var(--primary);
    background-color: white; 
    border-radius: 50%; 
    border: 1px solid var(--primary); 
    padding: 6px; 
    width: 40px; 
  }
} */