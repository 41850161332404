.clubPage.rtl * {
  text-align: right !important;
}

.clubPage {
  padding: 6vh 7vw 0;
}

.clubPage .clubPrivilegesList .clubPrivilegesListH {
  font-size: 32px;
  font-weight: 700;
  color: var(--primary);
}

.clubPage .clubPrivilegesList .clubPrivilegesListP {
  font-size: 26px;
  font-weight: 500;
  color: #77838f;
}

.clubPage .clubPrivilegesList .clubPrivilegesImgDiv .clubPrivilegesImg {
  border-radius: 10px;
  object-fit: cover;
}

.clubPage .clubPrivilegesList .clubPrivilegesImgDiv .clubPrivilegesImgP {
  font-size: 18px;
  font-weight: 400;
  color: #a5a6a8;
  margin-bottom: 0;
  padding: 20px 0;
}

.clubPage
  .clubPrivilegesList
  .clubPrivilegesImgDiv
  .clubPrivilegesImgP
  .clubPrivilegesImgSpan {
  color: var(--primary);
}

.clubPage .clubPrivilegesList .clubPrivilegesSmallImgList .row {
  flex-direction: row-reverse;
}

.clubPage
  .clubPrivilegesList
  .clubPrivilegesSmallImgList
  .clubPrivilegesSmallImgDiv
  .clubPrivilegesSmallImgDivImg {
  border-radius: 10px;
  object-fit: cover;
}

.clubPage
  .clubPrivilegesList
  .clubPrivilegesSmallImgList
  .clubPrivilegesSmallImgDiv
  .clubPrivilegesSmallImgDivP {
  font-size: 1.01vw;
  font-weight: 400;
  color: #a5a6a8;
  margin-bottom: 0;
  padding: 10px 0;
}

@media all and (max-width: 990px) {
  .clubPage .clubPrivilegesList .clubPrivilegesSmallImgList .clubPrivilegesSmallImgDiv .clubPrivilegesSmallImgDivP {
    font-size: 18px;
  }
}