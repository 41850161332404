.page {
  /*  max-width: 428px;
  width: 100%;*/
  text-align: right !important;
}

.postPage_2 .postImgDiv {
  height: 280px;
}
.postPage_2 .postPage_doctor {
  padding: 10px 10px;
  text-align: right;
}
.postPage_2 .postImgText {
  position: relative;
  top: -95px;
  padding: 21px 21px 17px 9px;
}
.postPage_2 .postImgPhoto {
  width: 100%;
  height: 285px;
}
.postPage_2 .postImgH {
  font-size: 24px;
  line-height: 1.28;
  color: white;
  font-family: "Poppins";
  margin: 15px 0px;
  text-align: end;
  /*direction: rtl;*/
}
.postPage_2 .doctor_detail p {
  padding: 15px 10px;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 300;
  color: black;
  direction: rtl;
}
.postPage_2 .postPage_video_div .our_department_video {
  height: 100%;
  width: 100%;
}

.postPage_2 .postPage_video_div .postPage_video_div_p {
  padding: 10px;
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
  font-family: "Poppins";
}

.postPage_2 .postPage_video_div {
  margin-bottom: 100px;
}
