.aboutPage * {
	color: black;
	text-align: right;
}

.aboutPage {
    display: flex;
    flex-direction: column;
    align-items: center;
	margin: 0 0 100px 0;
	padding: 0 21px;
}

.aboutH {
	color: var(--primary);
}

.aboutProject {
	margin-bottom: 25px;
	direction: rtl;
}

.aboutProject span {
	color: var(--primary);
	text-decoration: underline;
}

.paragraph {
	display: flex;
	flex-direction: row-reverse;
	padding-bottom: 15px;
}

.paragraph .paragraphImg {
	width: 44px;
    height: 45px;
    margin-left: 20px;
	fill: var(--primary);
}

.paragraph:nth-child(5) .paragraphImg {
	width: 132px;
}