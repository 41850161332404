.gynecologyPage {
	margin-bottom: 100px;
}

.gynecologyMain {
    padding: 0 21px;
}

.gynecologyPage .doctors_sec {
    padding: 10px 0 30px;
}

.gynecologyPage .doctors_sec .container {
    padding: 0
}

.gynecologyPage .doctor_heading {
	justify-content: space-between;
	display: flex;
}

.gynecologyPage .healthList {
    margin-bottom: 1px
}

.gynecologyPage .healthSquareContent {
    /*position: relative;*/
    /*top: -105px;*/
    /*display: flex;*/
    /*flex-direction: row-reverse;*/
    /*justify-content: space-between;*/
    /*align-items: center;*/
    padding: 45px 19px 25px;
    height: 96px;
}

.gynecologyPage a:nth-child(1) .healthSquare {
    background-image: url('../../../assets/images/gynecology/departments.jpg');
}

.gynecologyPage a:nth-child(2)  .healthSquare {
    background-image: url('../../../assets/images/gynecology/news.jpg');
}

.gynecologyPage a:nth-child(3) .healthSquare {
    background-image: url('../../../assets/images/gynecology/advice.jpg');
}

.gynecologyPage .service_list_sec .container {
    padding: 0
}

#serviceImg {
    width: 66px;
    height: 66px;
}

.gynecologyPage .service_heading  {
    display: flex;
    direction: rtl;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 8px 0 21px;
}

.blog {
    display: flex;
    justify-content: space-between;
}

.gynecologyPage .row-rev {
    flex-direction: row-reverse;    
}

.blogImg {
    width: 180px;
}

.blogContent {
    direction: rtl;
    width: 180px;
    padding: 39.5px 10px 39.5px;
}

.gynecologyPage .pad {
    padding: 39.5px 0px 39.5px 10px;
}

.blogContentH {
    color: var(--secondry);
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 30px;
    line-height: 1.53;
}

.blogContentP {
    font-size: 13px;
    line-height: 1.53;
    color: #A5A6A8;
}

.gynecologyPage .healthList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.gynecologyPage .healthSquare {
	height: 96px;
    margin: 5px;
}

@media all and (max-width: 500px) {
    .blogContent, .gynecologyPage .pad {
        padding-top: 19.5px;
    }

    .blogContentH {
        margin-bottom: 15px;
    }
}
