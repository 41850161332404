.contactPage {
  margin: 0 0 100px;
}

.detailsList {
  background-image: linear-gradient(to right, #fafafa, #f7f7f7, #fafafa);
}

.detailSquare {
  background-color: white;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  padding: 20px 53px 24px 0px;
  margin-bottom: 12px;
}

.detailSquare:nth-child(1) {
  padding: 0;
  align-items: center;
  padding-right: 21px;
}

.detailSquare:nth-child(1) .detailInfo {
  color: var(--primary);
  font-size: 25px;
  font-weight: 500;
}

.detailSquare:nth-child(1) button {
  color: var(--secondry);
}

/* .detailSquare:nth-child(2) .detailImgDiv {
  padding-top: 8px;
  padding-left: 28px;
} */

.detailImgDiv {
  padding-left: 18px;
}

.detailImgDiv .detailImg {
  font-size: 45px;
  fill: var(--primary);
}

/* .detailSquare:nth-child(1) img {
  width: 100px;
}

.detailSquare:nth-child(2) img {
  width: 36px;
}

.detailSquare:nth-child(3) img {
  width: 36px;
}

.detailSquare:nth-child(2) .detailInfo {
  font-size: 24px;
} */

.detailTextDiv {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.detailInfo {
  font-size: 20px;
  font-weight: 300;
  line-height: 29px;
}

.detailEdit {
  background-color: transparent;
  border: 0;
  color: #8f9bb3;
  text-align: right;
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  margin-left: 10px;
}

.detailCancel {
  background-color: transparent;
  border: 0;
  color: #8f9bb3;
  text-align: right;
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
}

@media all and (max-width: 500px) {
  .detailInfo {
    font-size: 16px;
  }

  .detailSquare:nth-child(2) .detailInfo {
    /* font-size: 20px; */
  }
}

.detailsList .detailSquare .detailTextDiv .uploadBtnArea {
  display: flex;
}

.detailsList .detailSquare .detailTextDiv .uploadBtnArea .uploadBtn {
  border-radius: 25px !important;
  background-color: var(--primary) !important;
  font-size: 10px;
  padding: 5px 10px;
}

.detailsList .detailSquare .detailTextDiv .uploadBtnArea .cancleBtn {
  border-radius: 25px !important;
  background-color: #6a6a6a !important;
  margin-right: 5px;
  font-size: 10px;
  padding: 5px 10px;
}

.detailsList .detailSquare .detailImgDiv .roundBox {
  width: 102px;
  height: 102px;
  padding: 6px;
  background: var(--profileSecondCircle);
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  box-shadow: 0 3px 6px #00000029;
  position: relative;
  z-index: 1;
}

.detailsList .detailSquare .detailImgDiv .roundBox .innerRoundBox {
  width: 90px;
  height: 90px;
  padding: 6px;
  background: var(--profileFirstCircle);
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  z-index: 1;
}

.detailsList .detailSquare .detailImgDiv .roundBox .innerRoundBox .detailImg {
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.detailsList .detailSquare .detailTextDiv .userDetail {
  text-align: right;
  color: #6a6a6a;
  width: 80%;
}

.detailsList input:focus,
.detailsList input:active {
  outline: none !important;
  box-shadow: none !important;
}

.detailsList .form-control:focus,
.detailsList .form-select:focus {
  border: 1px solid var(--primary) !important;
}