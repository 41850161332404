.pregnancyPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px
}

.pregnancyPage * {
	text-align: right;
}

