.hospital_open_round {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 13.5px 10px 13.5px 0px;
}

.hospital_open_round .imgAndText {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.hospital_open_round .imgAndText .openRoundImg {
  width: 66px;
  height: 66px;
  margin-left: 20px;
  border-radius: 15%;
  object-fit: cover;
}

.hospital_open_round .imgAndText .openRoundText {
  max-width: 100%;
}

.hospital_open_round .imgAndText .openRoundText .openRoundTextH {
  font-size: 15px;
  line-height: 1.33;
  color: #222b45;
  font-weight: 300;
  margin-bottom: 0px !important;
}

.hospital_open_round .imgAndText .openRoundText .openRoundTextP {
  font-size: 13px;
  line-height: 1.23;
  color: #8f9bb3;
}

.hospital_open_round .imgAndText .openRoundText .starSection {
  font-size: 10px;
  display: flex;
  direction: rtl;
}

.hospital_open_round .imgAndText .openRoundText .starSection .starImg {
  margin-left: 5px;
  height: 16px;
  width: 16px;
  margin-top: 1px;
  fill: var(--primary);
}

.hospital_open_round .imgAndText .openRoundText .starSection p {
  font-size: 13px;
  font-weight: 200;
  color: #8f9bb3;
}

.hospital_open_round .hospitalsbtn {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 12px;
}

.webHospitalsPage .hospitalsbtn > * {
  width: 72px;
  height: 26px;
  background-image: linear-gradient(90deg, var(--primary), var(--secondry));
  color: white;
  border-radius: 5px;
  line-height: 26px;
  text-align: center;
}

@media all and (max-width: 400px) {
  .hospital_open_round .imgAndText .openRoundText .openRoundTextH {
    font-size: 13px;
  }
  .hospital_open_round .imgAndText .openRoundText .starSection p {
    font-size: 11px;
  }
}
