.treatmentsSearchBanner .container {
  background: transparent
    url("../../../../../assets/images/web/treatmentsSearch/banner.jpg");
  height: 400px;
  border-radius: 30px;
  object-fit: cover;
  background-position: center;
  position: relative;
}

.treatmentsSearchBanner .container .bannerContent {
  position: absolute;
  bottom: 6%;
  width: 100%;
}

.treatmentsSearchBanner .container .bannerContent .bannerDiv {
  padding-top: 15%;
}

.treatmentsSearchBanner .container .bannerContent .bannerDiv .bannerDivH1 {
  font-size: 54px;
  font-weight: 700;
  color: white;
}

.treatmentsSearchBanner
  .container
  .bannerContent
  .bannerSearchDiv
  .bannerSearchDivInputGroup {
  width: 40%;
  border-radius: 15px;
}

.treatmentsSearchBanner
  .container
  .bannerContent
  .bannerSearchDiv
  .bannerSearchDivInputGroup
  .bannerSearchDivInput {
  text-align: right !important;
  border-radius: 0;
  border-left: 0;
}

.treatmentsSearchBanner
  .container
  .bannerContent
  .bannerSearchDiv
  .bannerSearchDivInputGroup
  .input-group-text {
  background-color: white;
}

.treatmentsSearchBanner
  .container
  .bannerContent
  .bannerSearchDiv
  .bannerSearchDivInputGroup
  svg
  > path {
  color: var(--primary);
}

@media only screen and (max-width: 1000px) {
  .treatmentsSearchBanner .container .bannerContent .bannerDiv .bannerDivH1 {
    font-size: 35px;
  }
}

.treatmentsSearchBanner input:focus,
.treatmentsSearchBanner input:active {
  outline: none !important;
  box-shadow: none !important;
}
