.departmens {
  padding: 10px 15px 0px 15px;
}

.departmensRow {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 11px;
  /* padding: 0 21px; */
  flex-wrap: wrap;
}

.departmens .departmensRow:last-child {
  margin: 0;
}

.departmensHeader {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  /* padding: 20px 20px 11px; */
  height: 52px;
}

.departmensHeaderH {
  direction: rtl;
  color: #222b45;
  font-size: 17px;
  line-height: 1.41;
}

/*.departmensHeaderImgDiv {
    height: 30px;
    align-items: center;
    display: flex;
}*/

.departmensHeaderImg {
  width: 100px;
  height: 21px;
}

.departmentSquareDiv {
  width: 30%;
  height: 141px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.departmentSquareImg {
  /*height: 109px;*/
}

#departmentSquareH {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.6;
  text-align: center !important;
  color: #222b45;
}
