.headNotifications {
    display: none;
    position: fixed;
    z-index: 1;
    background-color: #80808047;
    max-width: 428px;
    width: 100%;
    top: 38px;
    padding: 10px 15px;
    text-align: center;
    z-index: 9999;
}

#headNotificationsBtn {
    left: 5px;
}

.notificationsEmpty {
    display: none;
}

.notificationsEmptyWrap {
    display: flex;
    justify-content: center;
}