.webNav {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 8px 1.3vw 11px;
    width: 100%;
    height: 65px;
    position: fixed;
    top: 0;
    background: white;
    border-bottom: 1px solid lightgray;
    z-index: 10;
}

.side {
  display: flex;
}

.navHDiv {
  /*width: 9vw;*/
  direction: rtl;
}

.navH {
  /* color: var(--primary); */
  /* font-size: 20px; */
  /* line-height: 1.5; */
  /* font-weight: 600; */
  /* padding-left: 2vw; */
  width: 9%;
  margin-top: 5px;
}

.navMenu {
  padding: 0 0 0 1.4vw;
}

.navMenuI {
  width: 15px;
  height: 10px;
  margin: 4px 1.4vw 0;
}

.navBtnsList {
  display: flex;
  flex-direction: row-reverse;
}

.navBtnDiv {
  padding-left: 1.3vw;
}

.navBtn {
  font-size: 16px;
  color: #707070;
}

.navBtn:hover {
  color: var(--primary);
}

.thisPage {
  color: var(--primary);
  font-weight: 600;
  border-bottom: solid 2px;
  border-radius: 2px;
}

.navSearchDiv {
  display: flex;
  align-items: center;
  background-color: #eaebec;
  width: -webkit-fill-available;
  height: 46px;
  padding: 15px;
  border-radius: 10px;
  direction: rtl;
  margin-left: 1.5vw;
}

.navSearchDiv svg {
  fill: var(--primary);
}

.navSearchInput {
  width: inherit;
  padding-right: 1vw;
  font-size: 14px;
  line-height: 1.5;
}

.navIconLink svg {
  margin-left: 0.3vw;
  color: #707070;
  padding: 8px;
  border-radius: 8px;
  font-size: 36px;
}

.thisIcon svg {
  fill: var(--primary);
}

.StartBtnDiv {
  margin-left: 0.5vw;
}

.StartBtn,
.StartBtn:hover,
.StartBtn:active {
  font-size: 18px;
  padding: 0.7vh 0.8vw;
  color: #707070;
  border-radius: 8px;
}

.StartBtn2,
.StartBtn2:hover,
.StartBtn2:active {
  background-color: var(--primary);
  color: white;
}


.webNav .microphone {
  background: transparent;
  padding: 0;
  border: 0;
  fill: var(--primary);
}