.SearchBanner .container  {
  background: transparent url("../../../../../assets/images/search/banner3.jpg");
  height: 400px;
  border-radius: 30px;
  object-fit: cover;
  background-position: center;
  /*position: relative;*/
}

.SearchBanner .container {
  position: relative;
}

.SearchBanner .container .bannerContent {
  position: absolute;
  bottom: 6%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SearchBanner .container .bannerDiv {
  padding-top: 20%;
}

.SearchBanner .container .bannerDiv .bannerDivH1 {
  font-size: 54px;
  font-weight: 700;
  color: white;
  text-align: center;
  direction: rtl;
}



.searchBanner .container .bannerContent .bannerDiv {
  padding-top: 20%;
}

.SearchBanner .container .bannerContent .bannerDiv .bannerDivH1 {
  font-size: 54px;
  font-weight: 700;
  color: white;
}

.SearchBanner
  .container
  .bannerContent
  .bannerSearchDiv {
    width: 40%
}

.SearchBanner
  .container
  .bannerContent
  .bannerSearchDiv
  .bannerSearchDivInputGroup {
  /*width: 40%;*/
  border-radius: 15px;
}

.SearchBanner
  .container
  .bannerContent
  .bannerSearchDiv
  .bannerSearchDivInputGroup
  .bannerSearchDivInput {
  text-align: right !important;
  border-radius: 0;
  border-left: 0;
}

.SearchBanner
  .container
  .bannerContent
  .bannerSearchDiv
  .bannerSearchDivInputGroup
  .input-group-text {
  background-color: white;
}

.SearchBanner
  .container
  .bannerContent
  .bannerSearchDiv
  .bannerSearchDivInputGroup
  svg
  > path {
  color: #bf2c7d;
}
/*
  .SearchBanner
    .container
    .SearchBannerDiv
    .SearchBannerDivH1 {
    font-size: 54px;
    font-weight: 700;
    color: white;
  }
  
  @media all and (max-width: 768px) {
    .SearchBanner
    .container
    .SearchBannerDiv
    .SearchBannerDivH1 {
      font-size: 25px;
    }
  }
  @media all and (max-width: 1024px) {
    .SearchBanner
    .container
    .SearchBannerDiv
    .SearchBannerDivH1 {
      font-size: 25px;
    }
  } */

@media only screen and (max-width: 1000px) {
  .searchBanner .container .bannerContent .bannerDiv .bannerDivH1 {
    font-size: 35px;
  }
}

.searchBanner input:focus,
.searchBanner input:active {
  outline: none !important;
  box-shadow: none !important;
}