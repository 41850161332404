.aboutDepartmentWebBanner .container .bannerImg {
    height: 400px;
    border-radius: 30px;
    object-fit: cover;
}

.aboutDepartmentWebBanner .container .bannerDiv {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1030;
  margin-right: 10%;
}

.aboutDepartmentWebBanner .container .bannerDiv .bannerDivTitle {
  font-size: 54px;
  font-weight: 700;
  color: white;
}

.aboutDepartmentWebBanner .container .bannerDiv .bannerDivDetail {
  font-size: 22px;
  font-weight: 400;
  color: white;
  text-align: right !important;
}
