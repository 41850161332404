/* Create the Default Paragraphs */
.doctor_pages.rtl * {
  text-align: right !important;
}
.doctor_pages {
  height: 100%;
  max-width: 428px;
  width: 100%;
}
/* Header Area */
.header_area {
  padding: 20px 0;
}
.header_area .btn {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px #00000012;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header_area .btn img {
  max-width: 17px;
}

/* Banner Section */
/* .banner{width: 100%;height: 250px;background: url('../images/Dentistry_Pharmacy_Building.jpg');background-repeat: no-repeat;background-position: center;background-size: cover;position: relative;display: flex;flex-direction: column;align-items: flex-start;justify-content: flex-end;} */
.banner::after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #0000004f;
}
.banner .menu_btn {
  position: absolute;
  top: 0px;
  right: 5px;
  z-index: 1;
}
.banner_content {
  position: relative;
  z-index: 1;
  padding: 15px;
  width: 100%;
}
.banner_content h1 {
  color: var(--White-color);
  font-weight: 500;
}
.banner_content h6 {
  color: var(--White-color);
  font-size: 17px;
  font-weight: 300;
}

/* Doctors secion */
.doctorpage {
  max-width: 428px;
  width: 428px;
}
.doctors_sec {
  padding: 20px 0 10px;
}
.doctor_heading a {
  font-size: 16px;
  color: var(--primary);
}
.doctor_heading a img {
  margin-left: 3px;
  transition: all 0.5s ease;
  max-width: 11px;
}
.doctor_heading h3 {
  font-size: 17px;
  font-weight: 300;
}
.doctor_box .box_img {
  max-width: 65px;
  margin: 0 auto;
  width: 100%;
  border-radius: 100%;
  overflow: hidden;
  height: 65px;
}
.doctor_box_area {
  padding: 15px 0 0;
  text-align: center;
}
.doctor_box_area .col-3 {
  padding: 0 5px;
}
.doctor_box h6 {
  font-size: 10px;
  margin: 5px 0 0;
  font-weight: 500;
}
.doctor_box p {
  font-size: 9px;
  margin: 0;
}
.doctor_box span {
  font-size: 12px;
  margin-top: 5px;
  margin-left: 5px;
}

/* Button */
.box_btn {
  padding: 15px 0;
  box-shadow: 0 0 18px #00000042;
  background: var(--White-color);
  text-align: center;
}
.box_btn .go_btn {
  max-width: 65%;
  width: 100%;
  margin: 0 auto;
  color: var(--White-color);
  background: #df2d86;
  border-radius: 5px;
  display: inline-block;
  padding: 5px 0;
  font-size: 16px;
  font-weight: 500;
}

.doctor_banner {
  width: 100%;
  height: 550px;
  background: url("../../../assets/images/Rectangle-Copy.png");
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.doctor_banner::after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #0000004f;
}
.doctor_banner .menu_btn {
  position: absolute;
  top: 0px;
  right: 5px;
  z-index: 1;
}
.banner_content small {
  color: var(--White-color);
  font-size: 15px;
  direction: rtl;
}
.banner_content small i {
  color: #df2d86;
}
.banner_content small img {
  padding-left: 5px;
}
.banner_content small svg, .review_rating_sec .review_box .starImg  {
  font-size: 16px;
  fill: var(--primary);
  margin-bottom: 4px;
}
.banner_content .all_reviews {
  background-image: linear-gradient(to right, var(--primary), var(--secondry));
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 15px;
  margin-right: 15px;
  font-family: "Poppins";
}
.doctor_banner .menu_btn {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1;
}
.back_arrow {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  color: var(--White-color);
}
.doc_desc {
  font-size: 15px;
  margin-top: 10px !important;
  bottom: 10px !important;
  direction: rtl;
}

.doctors_details_sec h6 {
  font-size: 17px;
  font-weight: 300;
}
.doctors_details_sec p {
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #222b45;
}

.service_box .box_round_img {
  max-width: 40px;
  height: 40px;
  border-radius: 50%;
  width: 100%;
  overflow: hidden;
}
.service_box .box_round_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.review_box h4 {
  font-size: 15px;
  font-weight: 400;
}
.review_box p {
  font-size: 15px;
  margin: 0;
}
.review_box span {
  font-size: 13px;
  font-weight: 400;
  color: #c5cee0;
}
.review_box .star i {
  color: #df2d86;
  font-size: 13px !important;
}
.review_box .star {
  font-size: 13px !important;
}
.box_con .star i {
  color: #df2d86;
  font-size: 13px !important;
}
.box_con .star {
  color: #8f9bb3;
  font-size: 13px !important;
  font-weight: 400;
}

.service_box_area .rating_box {
  background: #f4f4f4;
  padding: 10px;
  border-radius: 10px;
}
.service_box .review_box {
  border-bottom: none;
  width: 100%;
}
.service_box .review_box p {
  color: #8f9bb3;
}

.doctors_sec .container .doctors_area .chat_icon {
  position: relative;
  left: 17px;
  bottom: 5px;
  float: left !important;
}
/* .doctors_sec .container .doctors_area .chat_icon img {
  height: 60px;
} */
.service_box_area .rating_box {
  align-items: unset;
}
.certificate {
  margin-top: 15px;
  margin-bottom: 15px;
}

p .fa-graduation-cap,
p .fa-envelope {
  color: #df2d86;
}

.review_rating_sec {
  padding: 20px 0 10px;
}
.review_rating_sec .justify-between {
  justify-content: space-between;
}
.review_rating_sec .review_heading h3 {
  font-size: 17px;
  font-weight: 400;
}
.review_rating_sec .review_heading a {
  font-size: 15px;
  color: var(--primary) !important;
  font-weight: 400;
}
.review_rating_sec .review_heading a img {
  margin-right: 3px;
  margin-top: 4px;
  transition: all 0.5s ease;
  width: 9px;
  height: 9px;
}
.review_rating_sec .d-flex {
  display: flex;
}
.review_rating_sec .container {
  max-width: 767px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}
.doctors_sec .container {
  max-width: 767px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}

.doctor_icons img {
  vertical-align: bottom;
}
.doctor_pages.rtl .all_review_section {
  text-align: left !important;
}
.all_review_section small {
  float: right;
  padding-top: 5px;
}

.review_rating_sec .box_heading {
  flex-direction: row-reverse;
}

.doctor_icons p .graduation {
  background: var(--secondry);
  padding: 6px 5px 5px;
  border-radius: 50%;
}

.doctor_icons p .graduationDark {
  background: var(--primary);
  padding: 6px 5px 5px;
  border-radius: 50%;
}

.doctors_sec .chat_icon {
  background: var(--primary);
  width: 79px;
  position: absolute;
  right: 19px;
  bottom: 17px;
  display: flex;
  justify-content: center;
  height: 79px;
  align-items: center;
  border-radius: 50%;
}