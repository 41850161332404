.page {
  max-width: 428px;
  width: 100%;
}
.myappointment_page {
  margin-bottom: 200px;
}
.myappointment_page .tab {
  text-align: center;
}
.myappointment_page .new1 {
  border-top: 1px solid #edf1f7;
  margin-top: 12px;
}
.myappointment_page .link_1 {
  color: #fff;
  background-color: var(--primary);
  font-size: 15px;
  padding: 5px 5px;
  border-radius: 9px;
}
.myappointment_page .link_2 {
  color: #fff;
  background-color: var(--secondry);
  font-size: 15px;
  padding: 5px 15px;
  border-radius: 9px;
}
.myappointment_page .image_section {
  text-align: center;
  padding: 10px 10px;
  margin-top: 10px;
}
.myappointment_page .image_section .box_img {
  height: 180px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 13px;
  position: relative;
}
.myappointment_page .image_section .image_time {
  font-size: 13px;
  padding: 5px 0px;
}

.myappointment_page .tab .MuiToggleButtonGroup-root {
  background-color: var(--secondry);
  border-radius: 10px;
}
.myappointment_page .tab .MuiToggleButtonGroup-root:hover {
  background-color: var(--secondry);
}
.myappointment_page .tab .MuiToggleButton-root {
  color: #fff;
  padding: 7px 30px;
  width: 150px;
  font-size: 15px;
  border: 0px !important;
  text-transform: capitalize;
  margin: 3px 3px 3px 3px;
}

.myappointment_page .tab .MuiToggleButton-root.Mui-selected {
  background-color: var(--primary);
  border-radius: 10px;
  color: #fff;
}

.myappointment_page .image_section .box_img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.myappointment_page .image_section .box_img h6 {
  position: absolute;
  right: 12px;
  bottom: 8px;
  text-transform: none;
  color: #ffffff;
  font-size: 22px;
  letter-spacing: 1px;
  font-weight: 500;
  direction: rtl;
  margin-bottom: 0;
}
.myappointment_page .image_section .box_img .box_img_h6 {
  bottom: 30px;
}
.myappointment_page .image_section .box_img h6 p {
  color: #fff;
  font-size: 18px;
  text-align: right;
}
.myappointment_page .image_section .box_img .time_dt .time_dt_p {
  color: white;
  font-size: 13px;
  line-height: 26px;
  font-weight: 400;
}
.myappointment_page .image_section .box_img .time_dt {
  position: absolute;
  width: 72px;
  height: 26px;
  line-height: 30px;
  border-radius: 13px;
  left: 5px;
  top: 10px;
  background: var(--primary);
  background-image: linear-gradient(to right, var(--primary), var(--secondry));
}
