.addPage {
  /*padding: 38px 0;*/
}

.addDescription {
  text-align: right;
}

.addDateH {
  font-size: 27px;
  font-weight: 100;
}

.mbsc-page {
  width: 350px
}

.addEsc {
    position: absolute;
    right: 7px;
    top: 7px;
    border: 2px solid var(--primary);
    border-radius: 50%;
    font-weight: 600;
    color: var(--primary);
    padding: 4px 7px 3px;
    font-size: 15px;
}

.inputsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90%;
  width: 100%;
}

.textInputsDiv {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inputsDiv input {
  border: 0;
  border-bottom: 1px solid var(--primary);
  margin-bottom: 10px;
}

.addHeader {
    text-align: center;
    padding: 0 0 22px;
    font-size: 25px;
    font-weight: 500;
    color: var(--primary);
    font-family: 'Poppins';
}

.addHeader::placeholder {
  color: var(--primary);
}

.addDescription {
  font-family: 'Poppins';
  font-size: 22px;
  width: 84%;
}

.addFilesHeader {
    color: var(--primary);
    padding: 30px 0 15px;
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 1.5;
}

.photoInput {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
}

.photoInput label {
  padding-left: 15px;
}

/* .camera2 {
  width: 44px;
} */

.photoInput > * {
  color: black;
  font-size: 22px;
}

.photoInput > svg {
  fill: var(--secondry);
  font-size: 40px;
}


.imgInput {
  color: var(--primary);
  font-size: 25px;
  display: none;
}

.camera {
  padding-top: 10px;
}

.gallery > i {
  color: #CB5597;
  font-size: 43px;
}

.addDatePicker {
  text-align: center;
  margin-top: 40px;
}

.addDatePickerH {
  color: var(--primary);
  padding-bottom: 15px;
}

.mbsc-page {
  width: unset;
}

.OkBtn {
  position: relative;
  bottom: 82px;
  font-size: 15px;
  border: 2px solid var(--primary);
  border-radius: 16px;
  padding: 2px 40px;
  color: var(--primary);
  font-weight: bold;
}

.addContentOkBtn {
  bottom: -40px;
}

.addSubmitDiv {
  display: flex;
  justify-content: center;
}

.addSubmit {
  /*width: 386px;*/
  max-width: 386px;
  padding: 0 39vw;
  height: 48px;
  border-radius: 10px;
  border: 0;
  background-color: var(--primary);
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 50px 0 0px;
  text-align: center;
}

@media all and (min-width: 428px) {
  .addSubmit {
    width: 386px;
    padding: 0;
    margin-top: 35px;
  }
}