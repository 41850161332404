/* Create the Default Paragraphs */
.department_details_pages.rtl * {
    text-align: right !important;
}
.department_details_pages{
    height: 100%;
    max-width: 428px;
    width: 100%;
}

/* Other Typo */
.department_details_pages strong {font-weight: bold;}
.department_details_pages hr {border:0 #000 solid; border-top-width: 1px; clear: both; height: 0;}
.department_details_pages ol {list-style: decimal;}
.department_details_pages ul {list-style: disc;}

.department_details_pages .unlisted{list-style: none;}
/* 3. Images
------------------------------------------------------------------------------*/

/* Remove border around linked images */
.department_details_pages img {border: 0; border-style: none;max-width: 100%;}

/* 4. Links
------------------------------------------------------------------------------*/

/* Default Link Types */
.department_details_pages a {color: var(--pink-color);text-decoration:none;}
.department_details_pages a:hover {color: var(--secondar-color);}
.department_details_pages a:active {color: #e8941a;}
.department_details_pages a:focus {outline: none;}
.department_details_pages a.current{color: #e8941a;}
.department_details_pages input,textarea{font-family: 'Assistant';}

/* 5. Forms
------------------------------------------------------------------------------*/

.department_details_pages input[type='submit']{ -webkit-appearance:none; border:none; -webkit-text-size-adjust:none; }
.department_details_pages select{-webkit-appearance:none; -moz-appearance:none; -o-appearance:none; appearance:none;}
.department_details_pages textarea{overflow:auto;}
.department_details_pages input:focus::placeholder{font-size: 0;}
.department_details_pages ::-webkit-input-placeholder{ color: #000;}
.department_details_pages :-moz-placeholder{ color: #000;}
.department_details_pages ::-moz-placeholder{ color: #000;}
.department_details_pages :-ms-input-placeholder{ color: #000;}
.department_details_pages ::-ms-input-placeholder{ color: #000;}
.department_details_pages :placeholder-shown{ color: #000;}
/* Removes fieldset borders. even on Opea 7 */
.department_details_pages fieldset {border: 1px solid transparent;}

/* 6. Tables
------------------------------------------------------------------------------*/

/* Table Fixes */
.department_details_pages table {border-spacing: 0; border-collapse: collapse;}
.department_details_pages td {text-align: right; font-weight: normal;}


/* 7. Framework ------------------------------------------------------------------------------*/ /* Common CSS */
/*transition effect*/
.department_details_pages input,textarea,button,select{font-family: 'Helvetica';}
.department_details_pages button{border: none;outline: none;background: none;}

.department_details_pages a,.panner_box_inner .pannel_box,a::after,a::before ,.quick_area li,#btnmenu span::after,#btnmenu span::before,#btnmenu span{-webkit-transition: all 0.5s ease;-moz-transition: all 0.5s ease;-o-transition: all 0.5s ease;transition: all 0.5s ease;}

/* Page Layout */
.department_details_pages .container{max-width: 767px; margin:0 auto; padding:0 15px; width:100%;}
.department_details_pages .row{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;}
.department_details_pages .col-1, .department_details_pages .col-2,.department_details_pages .col-3,.department_details_pages .col-4,.department_details_pages .col-5,.department_details_pages .col-6,.department_details_pages .col-7,.department_details_pages .col-8,.department_details_pages .col-9,.department_details_pages .col-10,.department_details_pages .col-11,.department_details_pages .col-12{position:relative;width:100%;padding-right:15px;padding-left:15px;}
.department_details_pages .col-1{max-width:8.33333333%;}
.department_details_pages .col-2{max-width:16.66666667%;}
.department_details_pages .col-3{max-width:25%;}
.department_details_pages .col-4{max-width:33.33333333%;}
.department_details_pages .col-5{max-width:41.66666667%;}
.department_details_pages .col-6{max-width:50%;}
.department_details_pages .col-7{max-width:58.33333333%;}
.department_details_pages .col-8{max-width:66.66666667%;}
.department_details_pages .col-9{max-width:75%;}
.department_details_pages .col-10{max-width:83.33333333%;}
.department_details_pages .col-11{max-width:91.66666667%;}
.department_details_pages .col-12{max-width:100%;}


.department_details_pages .d-flex{display: flex;}
.department_details_pages .flex-wrap{flex-wrap: wrap;}
.department_details_pages .align-center{align-items: center;}
.department_details_pages .align-start{align-items: flex-start;}
.department_details_pages .align-end{align-items: flex-end;}
.department_details_pages .justify-center{justify-content: center;}
.department_details_pages .justify-start{justify-content: flex-start;}
.department_details_pages .justify-end{justify-content: flex-end;}
.department_details_pages .justify-between{justify-content: space-between;}


/* Header Area */
.department_details_pages .header_area{padding: 25px 0;}
.department_details_pages .header_area .btn{width: 30px;height: 30px;border-radius: 5px;box-shadow: 0 0 10px #00000012;display: flex;align-items: center;justify-content: center;}
.department_details_pages .header_area .btn img{max-width: 17px;}

/* Banner Section */
.department_details_pages .banner{width: 100%;height: 280px;background: url('../../../assets/images/department_banner.png');background-repeat: no-repeat;background-position: center;background-size: cover;position: relative;display: flex;flex-direction: column;align-items: flex-start;justify-content: flex-end;}
.department_details_pages .banner img{position: absolute;}
.department_details_pages .banner::after{width: 100%;height: 100%;content: '';position: absolute;top: 0;left: 0;background: #0000004f;}
.department_details_pages .banner .menu_btn{top: 15px;right: 50px;z-index: 1; width: 26px;}
.department_details_pages .banner_content{position: relative;z-index: 1;padding:  0  0 15px 10px;}
.department_details_pages .banner_content h1{color: var(--White-color);font-weight: 600;font-size: 25px;font:medium; width: 100%;padding-right: 10px;}
.department_details_pages .banner_content h6{color: var(--White-color);font-size: 15px;font-weight: 400;font:medium;padding-right: 10px;}

/* Button */
.department_details_pages .box_btn { padding: 15px 0; box-shadow: 0px -2px 5px 0px #00000042; background: var(--White-color);text-align: center;position:sticky;width: 100%;bottom: 0; margin-top: 15px;z-index: 9;}
.department_details_pages .box_btn .go_btn{max-width: 65%;width: 100%;margin: 0 auto;color: var(--White-color);background: var(--primary);border-radius: 10px;display: inline-block;padding: 8px 0;font-size: 17px;font-weight: 700;height: 40px;}

.department_details_pages.rtl * {text-align: right !important;}
.department_details_pages.rtl .box_btn * {text-align: center !important;}