.clubWebBanner .container {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.clubWebBanner .container .bannerImg {
  width: 100%;
  border-radius: 30px;
  object-fit: cover;
}

.clubWebBanner .container .bannerDiv {
  position: absolute;
  right: 36px;
  /*margin-right: 5%;*/
  bottom: 12%;
  z-index: 1030;
}

.clubWebBanner .container .bannerDiv .bannerDivTitle {
  font-size: 2.87vw;
  font-weight: 700;
  color: white;
  margin: 0;
}

.clubWebBanner .container .bannerDiv .bannerDivDetail {
  font-size: 22px;
  font-weight: 400;
  color: white;
  text-align: right !important;
}

@media all and (min-width: 1400px) {
  .clubWebBanner .container {
    width: 100%;
    max-width: 100%;
  }
}
