.departmentWebBanner {
  /* width: 90%; */
}

.departmentWebBanner .bannerImg {
  height: 400px;
  border-radius: 30px;
  object-fit: cover;
}

.departmentWebBanner .bannerDiv {
  position: absolute;
  right: 0;
  margin-right: 10%;
  bottom: 0;
  z-index: 1030;
}

.departmentWebBanner .bannerDiv .bannerDivTitle {
  font-size: 54px;
  font-weight: 700;
  color: white;
}

.departmentWebBanner .bannerDiv .bannerDivDetail {
  font-size: 22px;
  font-weight: 400;
  color: white;
  text-align: right !important;
}
