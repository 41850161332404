.wrapper {
  max-width: 767px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.wrapper .row {
  margin: 0;
}

h2,
h1,
h3,
h4,
h5,
h6,
p,
ul,
li,
a {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

h3 {
  font-size: 17px;
}

input,
textarea,
select,
button {
  font-family: "Poppins";
  outline: none;
  background: none;
}

a {
  text-decoration: none;
  color: var(--primary);
}

button {
  border: none;
  outline: none;
  background: none;
}

section {
  width: 100%;
  position: relative;
}

.hospitalFilterWrpper {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  background: #fff;
  right: auto;
  /* width: 100% !important; */
  /* max-width: 100% !important; */
  border-radius: 20px 20px 0px 0px;
  bottom: -100vw;
  max-height: 80%;
  z-index: 9;
  overflow-y: scroll;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.hospitalFilterWrpper .filter-icon-and-title-div {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  top: 0;
  background: #fff;
  z-index: 11;
}

.hospitalFilterWrpper .filter-title {
  border-bottom: 1px solid #edf1f7;
}

.hospitalFilterWrpper .filter-title h4 {
  font-size: 22px;
  color: #222b45;
}

.hospitalFilterWrpper .filter-title a {
  font-size: 17px;
  color: #8f9bb3 !important;
}

.hospitalFilterWrpper .filter-name h5 {
  font-size: 17px;
  color: #222b45;
}

.hospitalFilterWrpper .filter-item-list {
  border-bottom: 1px solid #edf1f7;
}

.hospitalFilterWrpper .filter-item {
  border-bottom: 1px solid #edf1f7;
}

.hospitalFilterWrpper .checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 23px;
  height: 23px;
  background: #edf1f7;
  border-radius: 50%;
  position: relative;
}

.hospitalFilterWrpper .checkbox:checked:after {
  content: url("../../../../assets/images/vitamins/victory.png");
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  background: var(--primary);
  border-radius: 50%;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

.hospitalFilterWrpper .filter-dropdown {
  display: none;
}

.hospitalFilterWrpper .filter-drpdwn-active {
  display: block !important;
}

.hospitalFilterWrpper .arrow-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.hospitalFilterWrpper .arrow-icon-active {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.hospitalFilterWrpper button.filter-btn {
  background: var(--primary);
  color: #fff;
  padding: 10px 127px;
  border-radius: 10px;
  font-size: 17px;
}

.hospitalFilterWrpper .filter-action {
  -webkit-box-shadow: #b3b3b342 0px -4px 10px;
  box-shadow: #b3b3b342 0px -4px 10px;
}

.hospitalFilterWrapperActive {
  display: block;
  bottom: 0vw !important;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  box-shadow: #b3b3b342 0px -4px 10px;
}

.hospitalFilterSectionSearch .d-flex {
  display: flex !important;
  flex-direction: row-reverse;
}

.hospitalFilterSectionSearch .justify-content-center {
  justify-content: center !important;
}

.hospitalFilterSectionSearch .p-3 {
  padding: 1rem !important;
}

.hospitalFilterSectionSearch .px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.hospitalFilterSectionSearch .align-items-center {
  align-items: center !important;
}

.hospitalFilterSectionSearch .justify-content-between {
  justify-content: space-between !important;
}

.hospitalFilterSectionSearch .h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.hospitalFilterSectionSearch .py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.hospitalFilterSectionSearch .py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.hospitalFilterSectionSearch [type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.hide {
  display: none;
}

.webHospitalsPage .filterContainer {
  background: #fff;
  right: auto;
  bottom: -100vw;
  max-height: 80%;
  z-index: 9;
  overflow-y: scroll;
  transition: all 0.4s ease-in-out;
}

.webHospitalsPage .hospitalFilterWrpper {
  width: 50%;
  max-width: 777px;
  border-radius: 20px 20px 0px 0px;
  overflow-y: scroll;
  transition: all 0.4s ease-in-out;
}
