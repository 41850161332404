/* Create the Default Paragraphs */
.vandor_details_pages.rtl * {
  text-align: right !important;
}

.vandor_details_pages {
  height: 100%;
  max-width: 428px;
  width: 100%;
}

.vandor_details_pages .description_div {
  margin-top: 5%;
  padding: 0px 10px 0px 10px;
}

.vandor_details_pages .description_div .description_div_h1 {
  font-size: 18px;
  color: #000;
}

.vandor_details_pages .description_div .description_div_p {
  font-size: 14px;
  color: #8e8e8e;
  direction: rtl;
}

.vandor_details_pages .vandor_details_list_sec .tabs .list-unstyled {
  display: flex;
  flex-direction: row-reverse;
  list-style-type: none;
}

.vandor_details_pages .col-6 {
  max-width: 50%;
}

.vandor_details_pages .vandor_details_list_sec {
  padding: 10px 10px;
  margin-bottom: 70px;
}

.vandor_details_pages .vandor_details_list_sec #tabs-nav li a {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 18px;
  color: #a5a6a8;
  padding: 6px 10px;
  border-radius: 50px;
  margin-bottom: 10px;
  display: inline-block;
}

.vandor_details_pages .vandor_details_list_sec #tabs-nav li.active a {
  background: var(--primary);
  color: #ffffff;
}

.vandor_details_pages .vandor_details_list_sec #tabs-content .list_box_area {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: flex;
}

.vandor_details_pages
  .vandor_details_list_sec
  #tabs-content
  .list_box_area
  .list_box {
  /* max-width: 180px; */
  width: 100%;
  /* margin: 0 12px 30px; */
  position: relative;
  padding-right: 5px;
  padding-left: 5px;
}

.vandor_details_pages
  .vandor_details_list_sec
  #tabs-content
  .list_box_area
  .list_box
  .box_img {
  overflow: hidden;
  margin-bottom: 0px;
  position: relative;
}

.vandor_details_pages
  .vandor_details_list_sec
  #tabs-content
  .list_box_area
  .list_box
  .box_img
  img {
  width: 100%;
  height: 180px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
}

.vandor_details_pages
  .vandor_details_list_sec
  #tabs-content
  .list_box_area
  .list_box
  .box_img
  span {
  text-align: center !important;
  margin-left: 5px;
}

.vandor_details_pages
  .vandor_details_list_sec
  #tabs-content
  .list_box_area
  .list_box
  .box_img
  .discount_dt {
  position: absolute;
  left: 16px;
  bottom: 15px;
  font-size: 15px;
  color: #ffffff;
  background: var(--primary);
  width: 63px;
  height: 35px;
  border-radius: 20px;
  text-align: center;
  line-height: 35px;
  font-weight: 600;
}

.vandor_details_pages
  .vandor_details_list_sec
  #tabs-content
  .list_box_area
  .list_box
  .box_img
  .time_dt {
  position: absolute;
  /*max-width: 161px;*/
  height: 37px;
  line-height: 37px;
  font-size: 15px;
  border-radius: 20px;
  right: 5px;
  bottom: 15px;
  background: #ffffff;
  /*width: 100%;*/
  text-align: center;
  font-weight: 700;
  margin: auto;
}

.vandor_details_pages .vandor_details_list_sec .time_dt .off15 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.vandor_details_pages
  .vandor_details_list_sec
  #tabs-content
  .list_box_area
  .list_box
  .box_img
  .time_dt
  p {
  margin: 0 10px;
}
.vandor_details_pages
  .vandor_details_list_sec
  #tabs-content
  .list_box_area
  .list_box
  .box_img
  .time_dt.dark {
  background-color: var(--primary);
}
.vandor_details_pages
  .vandor_details_list_sec
  #tabs-content
  .list_box_area
  .list_box
  .box_img
  .time_dt.dark
  strong {
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}

.vandor_details_pages
  .vandor_details_list_sec
  #tabs-content
  .list_box_area
  .list_box
  .box_img
  .time_dt
  strong {
  color: var(--primary);
}

.vandor_details_pages
  .vandor_details_list_sec
  #tabs-content
  .list_box_area
  .list_box
  .box_detail {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 5%;
  margin-bottom: 10%;
  padding: 0 9px;
  align-items: center;
}

.vandor_details_pages
  .vandor_details_list_sec
  #tabs-content
  .list_box_area
  .list_box
  .box_detail
  span {
  text-align: left !important;
}

.vandor_details_pages
  .vandor_details_list_sec
  #tabs-content
  .list_box_area
  .list_box
  .box_detail
  .product_title {
  width: 80%;

  color: #a5a6a8;
  font-weight: normal;
  word-wrap: break-word;
}

.vandor_details_pages
  .vandor_details_list_sec
  #tabs-content
  .list_box_area
  .list_box
  .box_detail
  .product_title
  p {
  font-size: 14px;
  margin-bottom: 2px !important;
}

.vandor_details_pages
  .vandor_details_list_sec
  #tabs-content
  .list_box_area
  .list_box
  .box_detail
  .product_title
  h6 {
  font-size: 16px;
  font-weight: 400;
  color: #8e8e8e;
  margin-bottom: 0;
}

.vandor_details_pages
  .vandor_details_list_sec
  #tabs-content
  .list_box_area
  .list_box
  .box_detail
  span {
  color: var(--primary);
  font-weight: 600;
  font-size: 16px;
  font-family: "poppins";
}

.vandor_details_pages
  .vandor_details_list_sec
  #tabs-content
  .list_box_area
  .list_box
  .wish_list_icon {
  position: absolute;
  top: 12px;
  left: 25px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #0000001a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
