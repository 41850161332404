/* Create the Default Paragraphs */
.nutricion_pages.rtl * {
  text-align: right !important;
}

.nutricion_pages {
  height: 100%;
  max-width: 428px;
  width: 100%;
}

.nutricion_pages .nutricion_pages_area {
  padding: 10px 10px 10px 10px;
}

.nutricion_pages .nutricion_pages_area .chart_list_title h2 {
  text-align: center !important;
  font-size: 18px;
  font-weight: 400;
  color:#8F9BB3;
  padding-top: 10px;
  text-transform: uppercase;
}
.nutricion_pages .nutricion_pages_area .chart_cal_title{
  padding: 10px 0;
}
.nutricion_pages .nutricion_pages_area .chart_cal_title h2 {
  text-align: center !important;
  font-size: 18px;
  font-weight: 400;
  color:#8F9BB3;
  text-transform: uppercase;
  direction: rtl;
}

.nutricion_pages .nutricion_pages_card {
  /* height: 375px; */
  box-shadow: 0px 0px 10px 0px #f2f2f2;
  text-align: center !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nutricion_pages .nutricion_pages_card .nutricion_pages_card_text {
    display: grid;
    padding-top: 10%;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nutricion_pages
  .nutricion_pages_card
  .nutricion_pages_card_text
  .nutricion_pages_card_text_h1 {
  font-size: 22px;
  font-weight: 500;
  color: var(--primary);
  text-align: center !important;
}

.nutricion_pages
  .nutricion_pages_card
  .nutricion_pages_card_text
  .nutricion_pages_card_text_img {
    position: absolute;
    margin-left: 30%;
    margin-top: 13%;
}

.nutricion_pages .nutricion_pages_card .my_chart {
  /*margin-left: 29%;*/
  width: 169px !important;
  height: 169px !important;
}

.nutricion_pages .nutricion_chart_list {
  text-align: left !important;
  margin-top: 15px;
  display: flex !important;
}

.nutricion_pages .nutricion_chart_list .nutricion_chart {
  display: unset !important;
  /*width: 65px !important;*/
  /*height: 65px !important;*/
  float: right;
  margin: 0px 7px;
}

.nutricion_pages .nutricion_chart_list .nutricion_chart .title{
  font-size: 10px;
  color:#8F9BB3;
  text-align: center !important;
  margin-top: 5px;
}

.nutricion_pages .all_recipes_area {
  padding: 20px 10px 10px 10px;
  margin-bottom: 100px;
}

.nutricion_pages .all_recipes_area .all_recipes_area_image_des_1 {
  position: absolute;
  right: 15px;
  bottom: 30%;
  text-transform: capitalize;
  color: #FFFFFF;
  font-size: 19px;
  letter-spacing: 1px;
  font-weight: 400;
  direction: rtl;
  margin-bottom: 0%;
}

.nutricion_pages .all_recipes_area .all_recipes_area_image_des_2 {
  position: absolute;
  right: 15px;
  bottom: 19%;
  text-transform: capitalize;
  color: #FFFFFF;
  font-size: 19px;
  letter-spacing: 1px;
  font-weight: 400;
  direction: rtl;
  margin-bottom: 0%;
}

.nutricion_pages .all_recipes_area .all_recipes_area_image_des_3 {
  position: absolute;
  right: 15px;
  bottom: 9%;
  text-transform: capitalize;
  color: #FFFFFF;
  font-size: 19px;
  letter-spacing: 1px;
  font-weight: 400;
  direction: rtl;
  margin-bottom: 0%;
}

.nutricion_pages .all_recipes_area .right_arrow_img {
  position: absolute;
  left: 20px;
  bottom: 20%;
  width: 7px;
  height: 15px;
}

.nutricion_pages .all_recipes_area .all_recipes_h1 {
  font-size: 22px;
  font-weight: 500;
  color: var(--primary);
  text-align: center !important;
}

.swiper-container .swiper-wrapper .swiper-slide .all_recipes_area_image {
  width: 100% !important;
}

.my_chart .progress_text{
  font-size: 15px;
  font-weight: 500;
  color: #8F9BB3;
  text-align: center !important;
  direction: rtl;
}

.my_chart .progress_percentage{
  font-size: 32px;
  color: var(--primary);
  text-align: center !important;
}

.nutricion_pages_area
.nutricion_pages_card
.nutricion_pages_card_text
.dayPickerInput {
  width: 160px;
  padding: 0 16% 0 12px;
  width: 136px;
  padding: 0 33% 0 12px;

}