.firstPage {
  height: 100vh;
  background-image: url("../../assets/images/opening/firstBG.jpg");
  background-size: cover;
  position: relative;
}

.firstPageContent {
  height: 30%;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: white;
  border-radius: 40px 40px 0 0;
  padding: 0 21px 0;
  border: 1px solid #e2e2e2;
}

.firstPageBtns {
  position: absolute;
  width: 90%;
  bottom: 23%;
}

.firstPageBtns div {
  height: 17%;
  text-align: center;
  font-size: 17px;
  line-height: 250%;
  font-weight: 700;
  border-radius: 10px;
}

.loginBtn {
  margin-bottom: 20px;
  border: 1px solid #e2e2e2;
  color: var(--primary);
}

.signinBtn {
  border: 1px solid #979797;
  color: white;
  background-color: var(--primary);
}

.loginPage {
  background-image: url("../../assets/images/opening/log&signBG.jpg");
}

.loginArrowDiv {
  padding: 44px 40px 15px;
}

.loginHDiv {
  padding: 0 40px;
}

.loginH {
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
  direction: rtl;
  color: white;
}

.loginPageContent {
  height: 47%;
}

.loginPage .loginBtn {
  margin: 0;
  border: 1px solid #979797;
  color: white;
  background-color: var(--primary);
}

.loginPage .signinBtn {
  margin: 0;
  border: 1px solid #e2e2e2;
  color: var(--primary);
  background-color: white;
}

.orDiv {
  position: relative;
  margin: 1px 0;
  display: flex;
  justify-content: center;
}

.orDiv:after {
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  background: #e2e2e2;
  content: "";
  width: 100%;
  display: block;
}

.or {
  color: var(--primary);
  position: relative;
  z-index: 1;
  width: 19%;
  background-color: white;
}

.loginPageInputs,
.signinPageInputs {
  margin-bottom: 2%;
}

.emailDiv,
.passwordDiv {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  align-items: center;
}

.loginPageInputs input {
  width: 100%;
  text-align: right;
  border-bottom: 1px solid #e2e2e2;
  height: 4.6vh;
  font-size: 15px;
}

.emailDiv img {
  width: 14px;
  height: fit-content;
}

.passwordDiv img {
  width: 20px;
  height: fit-content;
}

.person {
  left: -0.5%;
  position: absolute;
  width: 20px !important;
  height: 20px !important;
  z-index: 999;
}
.double-arrow {
  left: -0.5%;
  position: absolute;
  transform: rotate(180deg) !important;
  width: 20px !important;
  height: 20px !important;
  z-index: 999;
}

.double-arrow > path,
.person > path {
  color: var(--primary);
}

.password-show-hide {
  left: 0%;
  position: absolute;
  z-index: 999;
}

.password-show-hide svg > path {
  color: var(--primary);
}

.visible {
  width: 20px !important;
  height: 20px !important;
}

.visibleoff {
  width: 20px !important;
  height: 20px !important;
}

.loginPageInputsAndBtns {
  position: absolute;
  width: 90%;
  bottom: 5%;
}

.loginPageBtns {
  position: unset;
  width: unset;
  bottom: unset;
}

.forget {
  text-align: left;
  direction: rtl;
  font-size: 12px;
  color: var(--primary);
  /*padding-top: 12px;*/
}

.signinPageInputs input {
  width: 100%;
  text-align: right;
  border-bottom: 1px solid #e2e2e2;
  height: 3.6vh;
  font-size: 15px;
}

.loginPageInputsAndBtns img {
  left: 3px;
  bottom: 7px;
}

.signinPageInputs > div,
.loginPageInputs > div {
  position: relative;
}

.signinPageBtns {
  display: flex;
  flex-direction: column-reverse;
}

.signinPageBtns .loginBtn {
  border: 1px solid #e2e2e2;
  color: var(--primary);
  background-color: white;
}

.signinPageBtns .signinBtn {
  border: 1px solid #979797;
  color: white;
  background-color: var(--primary);
}

.loginPageInputsAndBtns .form-control:focus {
  box-shadow: none !important;
}
.loginPageInputsAndBtns .form-control,
.loginPageInputsAndBtns .form-control:invalid {
  border: 0px !important;
  border-bottom: 1px solid #e2e2e2 !important;
}

.loginPageInputsAndBtns .form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 0px !important;
  border-bottom: 1px solid red !important;
}

@media all and (min-width: 350px) {
  .loginPageContent {
    height: 50% !important;
  }
}

@media all and (min-width: 500px) {
  .openingPage {
    max-width: 300px;
  }

  .firstPageContent {
    height: 30%;
  }

  .loginPageContent {
    height: 50%;
    flex-direction: column;
    align-items: center;
  }

  .loginPageInputsAndBtns {
    bottom: 10%;
    width: 85%;
  }

  /* .emailDiv {
		margin-bottom: 12px;
	} */

  .firstPageBtns div {
    font-size: 13px;
  }
}

@media all and (min-width: 780px) {
  .loginPageContent {
    height: 38% !important;
  }
}

/* --------------------------------------- CSS for Web Login Page --------------------------------------- */

.webLogin {
  position: absolute;
  top: 10%;
  bottom: 0;
  left: 0;
  right: 0;
}

/* .webLogin .webLoginMain {
  width: 70%;
} */

.webLogin .webLoginMain .webLoginCard .container .webLoginImgDiv {
  position: relative;
}

.webLogin .webLoginMain .webLoginCard .container {
  position: relative;
}

.webLogin .webLoginMain .webLoginCard .container .webLoginImgDiv .webLoginImg {
  position: relative;
}

.webLogin .webLoginMain .webLoginCard .container .webLoginImgBtn {
  position: absolute;
  top: 10%;
  left: 5%;
  z-index: 1;
  padding: 10px 30px 10px 30px;
  border-radius: 15px;
  background-color: white;
  color: var(--primary);
  border: 1px solid white;
  font-size: 12px;
  font-weight: 700;
}

.webLogin .webLoginMain .webLoginCard .container .webLoginImgBtn:active {
  color: var(--primary) !important;
  background-color: white !important;
  border: 1px solid white !important;
}

.webLogin .webLoginMain .webLoginCard .container .webLoginImgDiv .webLoginImgH {
  position: absolute;
  bottom: 8%;
  left: 44%;
  transform: translate(-37%, 0);
  color: white;
  font-weight: 700;
  font-size: 48px;
  width: 250px;
}

.webLogin .webLoginMain .webLoginCard .container .webLoginCardText {
  text-align: right !important;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardTextH {
  font-size: 28px;
  font-weight: 600;
  color: #171725;
  margin-bottom: 0;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardForm
  .webLoginCardFormLabel {
  font-size: 12px;
  font-weight: 400;
  color: #d5d5dc;
  margin-bottom: 0;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardForm
  .form-control {
  font-size: 16px;
  font-weight: 400;
  color: #44444f;
  border: 0px !important;
  border-bottom: 1px solid #e2e2e2 !important;
  border-radius: 0px;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardForm
  .form-control::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #44444f;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardForm
  .formMailIcon {
  left: 0%;
  position: absolute;
  z-index: 999;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardForm
  .webPasswordShowHide {
  left: 0%;
  position: absolute;
  z-index: 999;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardForm
  svg
  > path {
  color: #b5b5be;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardForm
  .webLoginForPassAndRemMeDiv {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardForm
  .webLoginForPassAndRemMeDiv
  .webLoginRememberMeDiv
  .input-group {
  flex-wrap: unset !important;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardForm
  .webLoginForPassAndRemMeDiv
  .webLoginRememberMeDiv
  .webLoginRememberMe:checked {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardForm
  .webLoginForPassAndRemMeDiv
  .webLoginRememberMeDiv
  .webLoginRememberMe {
  margin-left: 10px !important;
  border-radius: 0.25em;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardForm
  .webLoginForPassAndRemMeDiv
  .webLoginRememberMeDiv
  .webLoginRememberMeLabel {
  font-size: 14px;
  font-weight: 400;
  color: #696974;
  margin-bottom: 0;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardForm
  .webLoginForPassAndRemMeDiv
  .webLoginForgotPasswordDiv
  .webLoginForgotPasswordLink
  .webLoginForgotPasswordText {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary);
  margin-bottom: 0;
  direction: rtl;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardForm
  .webLoginFormBtnDiv {
  text-align: center !important;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardForm
  .webLoginFormBtnDiv
  .webLoginCardFormLoginBtn {
  font-size: 12px;
  font-weight: 600;
  background-color: var(--primary);
  color: white;
  border-radius: 30px;
  border: 1px solid var(--primary);
  width: 90%;
  text-align: center !important;
  padding: 15px 0px 15px 0px;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .instantLoginLine
  h2 {
  font-size: 14px;
  font-weight: 400;
  text-align: center !important;
  border-style: dashed;
  border-bottom: 1px solid #e2e2ea;
  position: relative;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .instantLoginLine {
  display: block;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .instantLoginLine
  h2
  span {
  color: #b5b5be;
  background-color: white;
  position: relative;
  top: 10px;
  padding: 0 10px;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardForm
  input {
  text-align: right !important;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginGoogleBtnDiv {
  text-align: center !important;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginGoogleBtnDiv
  .webLoginGoogleBtn {
  font-size: 12px;
  font-weight: 600;
  background-color: #fc5a5a;
  color: white;
  border-radius: 30px;
  border: 1px solid #fc5a5a;
  width: 90%;
  text-align: center !important;
  padding: 15px 0px 15px 0px;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginGoogleBtnDiv
  .webLoginGoogleBtn:active {
  background-color: #fc5a5a !important;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginFacebookBtnDiv {
  text-align: center !important;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginFacebookBtnDiv
  .webLoginFacebookBtn {
  font-size: 12px;
  font-weight: 600;
  background-color: #0062ff;
  color: white;
  border-radius: 30px;
  border: 1px solid #0062ff;
  width: 90%;
  text-align: center !important;
  padding: 15px 0px 15px 0px;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginFacebookBtnDiv
  .webLoginFacebookBtn:active {
  background-color: #0062ff !important;
}

.webLogin .webLoginMain .webLoginCard .container .webLoginCardText svg {
  height: 20px;
  width: 20px;
  margin-right: 1%;
}

.webLogin .webLoginMain .webLoginCard .container .webLoginCardText svg > path {
  color: white;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardForm
  .form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 0px !important;
  border-bottom: 1px solid red !important;
}

.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  .webLoginCardForm
  .webLoginFormGroup {
  margin-bottom: 0.5rem !important;
}

.webLogin .webLoginMain .webLoginCard .container .webLoginCardText input:focus,
.webLogin .webLoginMain .webLoginCard .container .webLoginCardText input:active,
.webLogin .webLoginMain .webLoginCard .container .webLoginCardText button:focus,
.webLogin
  .webLoginMain
  .webLoginCard
  .container
  .webLoginCardText
  button:active,
.webLogin .webLoginMain .webLoginCard .container .webLoginImgBtn:focus,
.webLogin .webLoginMain .webLoginCard .container .webLoginImgBtn:active {
  outline: none !important;
  box-shadow: none !important;
}

/* --------------------------------------- CSS for Web Login Page --------------------------------------- */

/* --------------------------------------- CSS for Web Register Page --------------------------------------- */

.webSignUp {
  position: absolute;
  top: 10%;
  bottom: 0;
  left: 0;
  right: 0;
}

/* .webSignUp .webSignUpMain {
  width: 70%;
} */

.webSignUp .webSignUpMain .webSignUpCard .container .webSignUpImgDiv {
  position: relative;
}

.webSignUp .webSignUpMain .webSignUpCard .container {
  position: relative;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpImgDiv
  .webSignUpImg {
  position: relative;
}

.webSignUp .webSignUpMain .webSignUpCard .container .webSignUpImgBtn {
  position: absolute;
  top: 10%;
  left: 5%;
  z-index: 1;
  padding: 10px 30px 10px 30px;
  border-radius: 15px;
  background-color: white;
  color: var(--primary);
  border: 1px solid white;
  font-size: 12px;
  font-weight: 700;
}

.webSignUp .webSignUpMain .webSignUpCard .container .webSignUpImgBtn:active {
  color: var(--primary) !important;
  background-color: white !important;
  border: 1px solid white !important;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpImgDiv
  .webSignUpImgH {
  position: absolute;
  bottom: 8%;
  left: 44%;
  transform: translate(-37%, 0);
  color: white;
  font-weight: 700;
  font-size: 48px;
  width: 250px;
}

.webSignUp .webSignUpMain .webSignUpCard .container .webSignUpCardText {
  text-align: right !important;
  height: 100%;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  .webSignUpCardTextH {
  font-size: 28px;
  font-weight: 600;
  color: #171725;
  margin-bottom: 0;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  .webSignUpCardForm {
  height: 100%;
  position: relative;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  .webSignUpCardForm
  form {
  height: 100%;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  .webSignUpCardForm
  .webSignUpCardFormLabel {
  font-size: 12px;
  font-weight: 400;
  color: #d5d5dc;
  margin: 0;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  .webSignUpCardForm
  .webSignUpCardFormEmail {
  direction: rtl;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  .webSignUpCardForm
  .form-control {
  font-size: 16px;
  font-weight: 400;
  color: #44444f;
  border: 0px !important;
  border-bottom: 1px solid #e2e2e2 !important;
  border-radius: 0px;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  .webSignUpCardForm
  .form-control::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #44444f;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  .webSignUpCardForm
  .formUserIcon {
  left: 0%;
  position: absolute;
  z-index: 999;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  .webSignUpCardForm
  .formMailIcon {
  left: 0%;
  position: absolute;
  z-index: 999;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  .webSignUpCardForm
  .formLanguageIcon {
  left: 0%;
  position: absolute;
  z-index: 999;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  .webSignUpCardForm
  .webPasswordShowHide {
  left: 0%;
  position: absolute;
  z-index: 999;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  .webSignUpCardForm
  svg
  > path {
  color: #b5b5be;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  .webSignUpCardForm
  .webSignUpFormBtnDiv {
  text-align: center !important;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  .webSignUpCardForm
  .webSignUpFormBtnDiv
  .webSignUpCardFormLoginBtn {
  font-size: 14px;
  font-weight: 600;
  background-color: var(--primary);
  color: white;
  border-radius: 30px;
  border: 1px solid var(--primary);
  width: 100%;
  text-align: center !important;
  padding: 15px 0px 15px 0px;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  .webSignUpCardForm
  input {
  text-align: right !important;
}

.webSignUp .webSignUpMain .webSignUpCard .container .webSignUpCardText svg {
  height: 20px;
  width: 20px;
  margin-right: 1%;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  svg
  > path {
  color: white;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  .webSignUpCardForm
  .form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 0px !important;
  border-bottom: 1px solid red !important;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  .webSignUpCardForm
  .webSignUpFormGroup {
  margin-bottom: 0.5rem !important;
}

.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  input:focus,
.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  input:active,
.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  button:focus,
.webSignUp
  .webSignUpMain
  .webSignUpCard
  .container
  .webSignUpCardText
  button:active,
.webSignUp .webSignUpMain .webSignUpCard .container .webSignUpImgBtn:focus,
.webSignUp .webSignUpMain .webSignUpCard .container .webSignUpImgBtn:active {
  outline: none !important;
  box-shadow: none !important;
}
