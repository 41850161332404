.toggleSquare {
	 display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 25px 21px 25px 17.5px;
    background-color: white;
    margin-bottom: 10px;
}

.toggleSquare > div {
	direction: rtl;
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
}

.switch input, .switch button { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--secondry);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 50%;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--primary);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary);
}

input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
