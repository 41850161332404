.listHeader span {
	color: var(--primary);
}

.listHeader {
	padding: 0 0 26px;
	height: 65px;
}

.listHeaderH {
	font-size: 14px;
	font-weight: 700;
	line-height: 1.5;
	color: #373C41;
}

.listHeaderP {
	font-size: 14px;
	font-weight: 500;
	line-height: 1.5;
	color: #373C41;
}