/* Create the Default Paragraphs */
.tell_us_pages.rtl * {
    text-align: right !important;
}

.tell_us_pages {
    height: 100%;
    max-width: 428px;
    width: 100%;
    margin-bottom: 100px;
}

.tell_us_pages .health_sec {
    padding: 10px 10px 0px 10px;
}

.tell_us_pages .health_sec .health_image_div {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.tell_us_pages .health_sec .health_image_div .health_h1 {
    /* margin-bottom: 0%; */
    /* margin-top: 5%; */
    margin-right: 2%;
    font-size: 22px;
    font-weight: 500;
}

.tell_us_pages button:active {
    background-color: var(--primary) !important;
    color: #FFFFFF !important;
}

.tell_us_pages button:hover {
    background-color: var(--primary) !important;
    color: #FFFFFF !important;
}

.tell_us_pages .active {
    background-color: var(--primary) !important;
    color: #FFFFFF !important;
}

.tell_us_pages button {
    background-color: #F7F7F9;
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    border-radius: 10px;
    height: 35px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    text-transform: capitalize;
    direction: rtl;
}

.tell_us_pages .pregnancy_sec {
    padding: 20px 10px 0px 10px;
}

.tell_us_pages .pregnancy_sec .pregnancy_image_div {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.tell_us_pages .pregnancy_sec .pregnancy_image_div .pregnancy_h1 {
    /* margin-bottom: 0%; */
    /* margin-top: 5%; */
    margin-right: 2%;
    font-size: 22px;
    font-weight: 500;
}

.tell_us_pages .roundBox {
    width: 50px;
    height: 50px;
    padding: 2.5px;
    background: var(--profileSecondCircle);
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    box-shadow: 0 3px 6px #00000029;
    position: relative;
    z-index: 1;
  }

.tell_us_pages .roundBox .innerRoundBox {
    width: 45px;
    height: 45px;
    padding: 2.5px;
    background: var(--profileFirstCircle);
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    position: relative;
    z-index: 1;
  }
  
  .tell_us_pages .roundBox .innerRoundBox img {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    height: auto;
  }