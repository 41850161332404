.birthPlanPage {
	padding: 0px 20.5px 0;
    margin-bottom: 100px;
}

.birthPlanPage * {
	text-align: right;
}

.birthPlanSquaresDiv {
  background-image: linear-gradient(to right, #FAFAFA , #F7F7F7, #FAFAFA);
}

.birthPlanSquare {
	padding: 23px 12px 13px 17px;
	margin-bottom: 10px;
	background-color: white;
	height: 93px;
}


.birthPlanSquareTop {
	display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}

.birthPlanSquareTextDivH {
	font-size: 15px;
	line-height: 1.33;
	color: #222B45;
}

.birthPlanSquareTextDivP {
	font-size: 13px;
	line-height: 1.23;
	color: #8F9BB3;
}


.birthPlanSquareBtn {
    width: 14px;
    height: 29px;
    padding: 10px 0;
}

.staffContent, .dateContent {
	display: none;
}

.checkList {
	display: none;
	padding: 5px 0;
}

.checkListItem {
	display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 5px;
}

.checkListItemV {
    width: 20px;
    margin: 0 0 0 15px;
    padding: 5px;
    background: #6A6A6A;
    border-radius: 50%;
}