.videoPage {
	height: 100vh;
	position: relative;
}

.videoBG {
	height: 100%;
    width: 100%;
    object-fit: cover;
}

.selfScreenImg {
    position: absolute;
    top: 46px;
    right: 27px;
    width: 109px;
    border-radius: 20px;
}

.backBtnImg {
    position: absolute;
    top: 46px;
    left: 28px;
    width: 44px;
}

.videoBtnsBoard {
    background-color: var(--primary);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 120px;
    border-radius: 30px 30px 0 0;
    z-index: 1;
}

.shareFilesBoard {
	background: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 235px;
    border-radius: 30px 30px 0 0;
    padding: 11px 25px 0 0;
    direction: rtl;
    display: none;
}

.shareFilesBoardH {
	color: white;
	font-weight: 600;
	font-size: 14px;
	line-height: 1.5;
}

.shareFilesBtns {
	display: flex;
	margin-top: 7px;
}

.fileBtn {
	color: white;
	width: 46px;
	height: 46px;
	background-color: var(--primary);
	border-radius: 10px;
	margin-left: 20px;
	color: white;
	font-weight: 600;
	font-size: 14px;
	line-height: 46px;
	text-align: center;
}

.fileChoosen {
	color: var(--primary);
	background-color: white;
}

.videoBtnsBoardNotOff {
    display: flex;
    justify-content: space-evenly;
    padding: 46px 0 28px;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
}

.videoBtnsBoard > .offLink > .offImg {
    position: absolute;
    bottom: 46px;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 73px;
}

.videoBtnsBoardNotOff > div:nth-child(2) {
	margin-right: 25px;
}

.videoBtnImg {
    width: 46px;
}

.fileBtnImg {
    fill: white;
    background: rgba(255, 255, 255, .16);
    padding: 10px;
    font-size: 46px;
    border-radius: 10px;
    width: unset;
}