/* Create the Default Paragraphs */
.hospitalpages.rtl * {
  text-align: right !important;
}
.hospitalpages {
  height: 100%;
  max-width: 428px;
  width: 100%;
}

/* Other Typo */
.hospitalpages strong {
  font-weight: bold;
}
.hospitalpages hr {
  border: 0 #000 solid;
  border-top-width: 1px;
  clear: both;
  height: 0;
}
.hospitalpages ol {
  list-style: decimal;
}
.hospitalpages ul {
  list-style: disc;
}

.hospitalpages .unlisted {
  list-style: none;
}
/* 3. Images
------------------------------------------------------------------------------*/

/* Remove border around linked images */
.hospitalpages img {
  border: 0;
  border-style: none;
  max-width: 100%;
}

/* 4. Links
------------------------------------------------------------------------------*/

/* Default Link Types */
.hospitalpages a {
  color: var(--pink-color);
  text-decoration: none;
}
.hospitalpages a:hover {
  color: var(--secondar-color);
}
.hospitalpages a:active {
  color: #e8941a;
}
.hospitalpages a:focus {
  outline: none;
}
.hospitalpages a.current {
  color: #e8941a;
}
.hospitalpages input,
textarea {
  font-family: "Assistant";
}

/* 5. Forms
------------------------------------------------------------------------------*/

.hospitalpages input[type="submit"] {
  -webkit-appearance: none;
  border: none;
  -webkit-text-size-adjust: none;
}
.hospitalpages select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.hospitalpages textarea {
  overflow: auto;
}
.hospitalpages input:focus::placeholder {
  font-size: 0;
}
.hospitalpages ::-webkit-input-placeholder {
  color: #000;
}
.hospitalpages :-moz-placeholder {
  color: #000;
}
.hospitalpages ::-moz-placeholder {
  color: #000;
}
.hospitalpages :-ms-input-placeholder {
  color: #000;
}
.hospitalpages ::-ms-input-placeholder {
  color: #000;
}
.hospitalpages :placeholder-shown {
  color: #000;
}
/* Removes fieldset borders. even on Opea 7 */
.hospitalpages fieldset {
  border: 1px solid transparent;
}

/* 6. Tables
------------------------------------------------------------------------------*/

/* Table Fixes */
.hospitalpages table {
  border-spacing: 0;
  border-collapse: collapse;
}
.hospitalpages td {
  text-align: right;
  font-weight: normal;
}

/* 7. Framework ------------------------------------------------------------------------------*/ /* Common CSS */
/*transition effect*/
.hospitalpages input,
textarea,
button,
select {
  font-family: "Helvetica";
}
.hospitalpages button {
  border: none;
  outline: none;
  background: none;
}

.hospitalpages a,
.panner_box_inner .pannel_box,
a::after,
a::before,
.quick_area li,
#btnmenu span::after,
#btnmenu span::before,
#btnmenu span {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/* Page Layout */
.hospitalpages .container {
  max-width: 767px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}
.hospitalpages .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.hospitalpages .col-1,
.hospitalpages .col-2,
.hospitalpages .col-3,
.hospitalpages .col-4,
.hospitalpages .col-5,
.hospitalpages .col-6,
.hospitalpages .col-7,
.hospitalpages .col-8,
.hospitalpages .col-9,
.hospitalpages .col-10,
.hospitalpages .col-11,
.hospitalpages .col-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.hospitalpages .col-1 {
  max-width: 8.33333333%;
}
.hospitalpages .col-2 {
  max-width: 16.66666667%;
}
.hospitalpages .col-3 {
  max-width: 25%;
}
.hospitalpages .col-4 {
  max-width: 33.33333333%;
}
.hospitalpages .col-5 {
  max-width: 41.66666667%;
}
.hospitalpages .col-6 {
  max-width: 50%;
}
.hospitalpages .col-7 {
  max-width: 58.33333333%;
}
.hospitalpages .col-8 {
  max-width: 66.66666667%;
}
.hospitalpages .col-9 {
  max-width: 75%;
}
.hospitalpages .col-10 {
  max-width: 83.33333333%;
}
.hospitalpages .col-11 {
  max-width: 91.66666667%;
}
.hospitalpages .col-12 {
  max-width: 100%;
}

.hospitalpages .d-flex {
  display: flex;
}
.hospitalpages .flex-wrap {
  flex-wrap: wrap;
}
.hospitalpages .align-center {
  align-items: center;
}
.hospitalpages .align-start {
  align-items: flex-start;
}
.hospitalpages .align-end {
  align-items: flex-end;
}
.hospitalpages .justify-center {
  justify-content: center;
}
.hospitalpages .justify-start {
  justify-content: flex-start;
}
.hospitalpages .justify-end {
  justify-content: flex-end;
}
.hospitalpages .justify-between {
  justify-content: space-between;
}

/* Header Area */
.hospitalpages .header_area {
  padding: 25px 0;
}
.hospitalpages .header_area .btn {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px #00000012;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hospitalpages .header_area .btn img {
  max-width: 17px;
}

/* Banner Section */
.hospital_banner {
  position: relative;
  width: 100%;
  height: 250px;
}

.hospital_banner .hospital_banner_img {
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.hospital_banner .hospital_banner_three_dots {
  position: absolute;
  left: 5%;
  top: 5%;
}

.hospital_banner .hospital_banner_three_dots .hospital_banner_three_dots_img {
  position: relative;
}

.hospital_banner .hospital_banner_content {
  position: absolute;
  bottom: 5%;
  color: white;
  font-weight: 700;
  font-size: 25px;
  right: 5%;
}

.hospital_banner .hospital_banner_content h1 {
  color: var(--White-color);
  font-weight: 600;
  font-size: 25px;
}

.hospital_banner .hospital_banner_content h6 {
  color: var(--White-color);
  font-size: 15px;
  font-weight: 400;
}

/* Button */
.hospitalpages .box_btn {
  padding: 15px 0;
  box-shadow: 0px -2px 5px 0px #00000042;
  background: var(--White-color);
  text-align: center;
  position: sticky;
  width: 100%;
  bottom: 0;
  margin-top: 15px;
  z-index: 9;
}
.hospitalpages .box_btn .go_btn {
  max-width: 65%;
  width: 100%;
  margin: 0 auto;
  color: var(--White-color);
  background: var(--primary);
  border-radius: 10px;
  display: inline-block;
  padding: 8px 0;
  font-size: 17px;
  font-weight: 700;
  height: 40px;
}

.hospitalpages.rtl * {
  text-align: right !important;
}
.hospitalpages.rtl .box_btn * {
  text-align: center !important;
}

.images-gallery {
  display: flow-root;
}
