.contactUsPages.rtl * {
  text-align: right !important;
}

.contactUsPages {
	padding: 6vh 7vw 0;
}

.contactUsPages .contactUsPagesTitle {
  font-size: 58px;
  font-weight: 600;
  color: #171725;
}

.contactUsPages .contactUsPagesContainer {
  max-width: 50%;
}

.contactUsPages .contactUsPagesContainer .contactUsFrom {
  text-align: right !important;
}

.contactUsPages .contactUsPagesContainer .contactUsFrom .contactUsFromRow {
  display: flex;
  flex-direction: row-reverse;
}

.contactUsPages .contactUsPagesContainer .contactUsFrom .form-control {
  font-size: 16px;
  font-weight: 400;
  color: #5a7184;
  border-radius: 10px;
}

.contactUsPages .contactUsPagesContainer .contactUsFrom .form-control {
  font-size: 16px;
  font-weight: 400;
  color: #5a7184;
  direction: rtl;
}

.contactUsPages .contactUsPagesContainer .contactUsFrom .form-select {
  font-size: 16px;
  font-weight: 400;
  color: #5a7184;
  border-radius: 10px;
}

.contactUsPages
  .contactUsPagesContainer
  .contactUsFrom
  .form-control::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #959ead;
}

.contactUsPages input:focus,
.contactUsPages input:active,
.contactUsPages textarea:focus,
.contactUsPages textarea:active,
.contactUsPages select:focus,
.contactUsPages select:active,
.contactUsPages button:focus,
.contactUsPages button:active {
  outline: none !important;
  box-shadow: none !important;
}

.contactUsPages .contactUsPagesContainer .contactUsFrom .form-control:focus,
.contactUsPages .contactUsPagesContainer .contactUsFrom .form-select:focus {
  border: 1px solid var(--primary) !important;
}

.contactUsPages .contactUsPagesContainer .contactUsFrom .contactUsFromBtn {
  background-color: var(--primary);
  color: white;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid var(--primary);
  border-radius: 10px;
}
