.doctor_img .doctorSquare {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
  background-color: white;
  margin-bottom: 10px;
}

.doctor_img .doctorSquare .doctor-btn {
  font-size: 14px;
  background-image: var(--gradient);
  border: none;
  color: white;
  padding: 8px 1px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 0px 0px;
  cursor: pointer;
  border-radius: 6px;
  width: 100px;
  height: 35px;
}

.doctor_img .doctorSquare .imgAndText {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.doctor_img .doctorSquare .imgAndText .doctorSquareImg {
  width: 66px;
  height: 66px;
  margin-left: 10px;
}

.doctor_img .doctorSquare .imgAndText .doctorSquareText {
  width: 203px;
}

.doctor_img .doctorSquare .imgAndText .doctorSquareText .doctorSquareTextH {
  font-size: 15px;
  line-height: 1.33;
  color: #222B45;
}

.doctor_img .doctorSquare .imgAndText .doctorSquareText .doctorSquareTextP {
  font-size: 13px;
  line-height: 1.23;
  color: #8F9BB3;
}

.review_section {
  text-align: center !important;
}

.review_section h2 {
  text-align: center !important;
  padding-top: 30px;
  font-size: 22px;
  font-family: "Poppins";
  font-weight: 200;
  direction: rtl;
}

.review_section .review_section_text {
  text-align: center !important;
  padding: 20px 10px;
  font-size: 13px !important;
  font-family: "Poppins";
  font-weight: 100 !important;
  padding-bottom: 35px;

}


.review_section .review_star {
  fill: var(--secondry);
}

.review_section .checked {
  fill: var(--primary);
}

.review_section .text_div .review_section_input {
  background-color: #f4f4f4;
  width: 100%;
  height: 85px;
  border-radius: 10px;
  font-size: 15px;
  color: #8F9BB3;
}

.review_section .text_div {
  padding: 15px 0px;
}

.review_section .text_div .review_section_input::placeholder {
  color: #8F9BB3;
  font-size: 15px;
}

.new1 {
  border-top: 1px solid #EDF1F7;
}

.doctor_img {
  padding-top: 30px;
}