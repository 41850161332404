/* Service box */
.hospital_list_sec {
  padding: 20px 0px 10px;
}
.hospital_list_sec .container {
  max-width: 767px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}
.hospital_list_sec .container .hospital_list_area .hospital_list_box_area {
  padding-top: 10px;
}
.hospital_list_sec
  .container
  .hospital_list_area
  .hospital_list_box_area
  .hospital_list_box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  margin-top: -10px;
}

.hospital_list_sec
  .container
  .hospital_list_area
  .hospital_list_box_area
  .hospital_list_box:last-child
  .box_con {
  border: none;
}
.hospital_list_sec
  .container
  .hospital_list_area
  .hospital_list_box_area
  .hospital_list_box
  .box_img {
  max-width: 80px;
  height: 70px;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
}
.hospital_list_sec
  .container
  .hospital_list_area
  .hospital_list_box_area
  .hospital_list_box
  .box_img
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hospital_list_sec
  .container
  .hospital_list_area
  .hospital_list_box_area
  .hospital_list_box
  .box_con {
  margin-right: 10px;
  width: calc(100% - 80px);
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
}

.hospital_list_sec
  .container
  .hospital_list_area
  .hospital_list_box_area
  .hospital_list_box
  .box_con
  .box_heading {
  float: right;
  display: block;
  margin-top: 10px;
}

.hospital_list_sec
  .container
  .hospital_list_area
  .hospital_list_box_area
  .hospital_list_box
  .box_con
  .box_heading
  .box_details {
    direction: rtl;
}

.hospital_list_sec
  .container
  .hospital_list_area
  .hospital_list_box_area
  .hospital_list_box
  .box_con
  h4 {
  font-size: 15px;
  font-weight: 300;
  color: #222b45;
}

.hospital_list_sec
  .container
  .hospital_list_area
  .hospital_list_box_area
  .hospital_list_box
  .box_con
  p {
  font-size: 13px;
  margin: 0;
  font-weight: 400;
  color: #8f9bb3;
}

.hospital_list_sec
  .container
  .hospital_list_area
  .hospital_list_box_area
  .hospital_list_box
  .box_con
  span {
  font-size: 13px;
  margin: 0;
  color: #8f9bb3;
  font-weight: 400;
}

.hospital_list_sec .container .hospital_list_area .justify-between {
  justify-content: space-between;
}

.hospital_list_sec .container .hospital_list_area .align-center {
  align-items: center;
}

.hospital_list_sec .container .hospital_list_area .hospital_list_heading a {
  font-size: 15px;
  color: var(--primary) !important;
  font-weight: 400;
}

.hospital_list_sec .container .hospital_list_area .hospital_list_heading a img {
  margin-right: 3px;
  transition: all 0.5s ease;
  max-width: 11px;
}

.hospital_list_sec .container .hospital_list_area .hospital_list_heading h3 {
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 0px;
}

.hospital_list_sec .d-flex {
  display: flex;
}

.hospital_list_sec .container {
  max-width: 767px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}
