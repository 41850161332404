.page {
  max-width: 428px;
  width: 100%;
}

.patientPage {
  margin-bottom: 100px;
}

.patientPage * {
  text-align: right;
}

.patientImgDiv {
  height: 280px;
}

.patientImgPhoto {
  width: 100%;
}

.patientImgText {
  position: relative;
  top: -95px;
  padding: 21px 21px 17px 9px;
}

.patientImgH {
  font-size: 25px;
  line-height: 1.28;
  color: white;
}

.patientImgP {
  font-size: 15px;
  line-height: 1.73;
  color: white;
}

.patientTextDiv {
  height: 70px;
  padding: 10px 20px 0px 15px;
}

.patientTextPDiv {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.patientTextH {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  color: #373c41;
}

.patientTextPText {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #373c41;
}

.patientTextPDate {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--primary);
}

.patientSquaresDiv {
  padding: 0 20.5px 0 7px;
}
