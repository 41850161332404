#serviceListHeader a img {
	max-width: unset;
}

.service_heading a  {
	color: var(--primary);
}

.service_heading a:hover  {
	color: var(--primary);
}

.service_heading a svg {
	margin: 0 5px 2px 0;
	fill: var(--primary);
}

#serviceListHeader a {
	color: var(--primary);
	font-size: 15px;
	direction: ltr;
}

.service_heading h3 {
	font-size: 17px;
	font-weight: 400;
	direction: rtl;
}