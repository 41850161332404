.toggleSquareImgDiv {
  padding: 17.5px 17.5px 17.5px 21px
}

.toggleSquareImgDiv .toggleSquareImg {
  /* width: 35px; */
  /* height: 35px; */
  font-size: 35px;
  fill: var(--primary);
}

.ImgAndH {
  display: flex;
  align-items: center;
  direction: rtl;
}

.ImgAndH .openSquareTextH {
  padding-right: 20px;
}

.toggleSquare .arrow {
  width: 24px;
  height: 24px;
}