.head {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 428px;
  height: 38px;
  top: 0;
  z-index: 5;
  background-color: white;
  padding: 0px 21px;
  margin: 20px auto;
  position: -webkit-sticky;
  /* Safari & IE */
  position: sticky;
  top: 0;
}

.head>* {
  font-size: 14px;
}

.menuIcon {
  margin: 0 11px;
}

.head .bellAndCircle {
  position: relative;
}

.head .bell {
  margin: 0 13px;
  width: 17px;
  ;
}

.head .bellCircle {
  position: absolute;
  font-size: 10px;
  fill: var(--primary);
  top: 0px;
  right: 8px;
}

.headHeader {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.0625em;
  line-height: 1.5625;
  font-weight: 700;
  color: #1E2022;
}

/* for logo  */

.logoDiv {
  display: flex;
  justify-content: center;
}

.logoImg {
  width: 100px;
  margin-top: 15px;
}

.backArrow {
  fill: black !important;
  padding: 10px;
  font-size: 45px !important;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}