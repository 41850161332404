.healthPage .SearchDiv {
	padding-bottom: 10px;
}


.healthPage .analyzesSquare {
	margin-bottom: 12px
}

.healthPage .dayPickerInput {
	width: 120px;
	padding: 0 31.5px 0 7px;
}
