.confirmPage .openRoundImg {
  object-fit: cover;
}

.confirmPage .startH2 {
  padding-bottom: 44px;
}

.confirmPage .openRound {
  padding: 0;
  text-align: right;
}

.confirmPage .openRoundText {
  padding-left: 20px;
}

.confirmPage .starSection .starImg {
  margin: 0 0 17px 5px;
}

.confirmPage .startMain .date_area {
  margin-top: 10%;
}

.confirmPage .startMain .date_area h1 {
  font-size: 18px;
  font-weight: 500;
  color: #222b45;
}

.confirmPage .startMain .date_area span {
  color: var(--primary);
}
