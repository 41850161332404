.productsList .tabsMenu li a {
	font-size: 16px;
}

.productsList .tabsMenu li.active a {
	font-size: 14px;
}

.productsList .tabsMenuLi {
	padding-left: 3%;
}

.productSquareContainer {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5px 10px;
    padding-bottom: 8vh;
}

.productSquareLink {
	width: 14.5%;
}

.productSquare {
    width: 100%;
}

.productSquareImg {
	/*background-image: url(../../../assets/images/web/productsList/image.jpg);*/
    background-size: cover;
    width: 100%;
    height: 0;
    padding-top: 100%;
    border-radius: 12px;
    position: relative;
}

.productSquareImgBG {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.productSquareImgHeart {
	position: absolute;
    top: 8%;
    left: 9%;
    width: 25%;
    height: 25%;
}

.productSquareImgOff {
    position: absolute;
    bottom: 8%;
    left: 9%;
    color: white;
    background-color: var(--primary);
    padding: 3% 7%;
    border-radius: 25px;
    font-weight: 700;
    font-size: calc(10.5px + 0.1vw);
}

.productSquareText {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 2vh 0.6vh;
}

.productSquareTextH {
	font-size: 12px;
	direction: rtl;
	color: #A5A6A8;
}

.productSquareTextPrice {
	font-size: 16px;
	color: var(--primary);
	padding-right: 5px;
}