.procedursPage {
	padding: 0px 20.5px 0;
    margin-bottom: 100px;
    text-align: right;
}

.procedursSquaresDiv {
  background-image: linear-gradient(to right, #FAFAFA , #F7F7F7, #FAFAFA);
}

.procedursSquare {
	display: flex;
	flex-direction: row-reverse;
	padding: 23px 12px 13px 17px;
	margin-bottom: 10px;
	background-color: white;
	height: 93px;
}

.procedursSquareDate {
	font-size: 15px;
	width: 66px;
	margin-left: 20px;
}

.procedursSquareDateDay {
	font-weight: 300;
	color: #222B45;
	text-align: center;
}

.procedursSquareDateDate {
	font-weight: 600;
	color: #222B45;
	text-align: center;
}

.procedursSquareTextDivH {
	font-size: 15px;
	line-height: 1.33;
	color: #222B45;
}

.procedursSquareTextDivP {
	font-size: 13px;
	line-height: 1.23;
	color: #8F9BB3;
}
