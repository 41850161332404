.departmentWebPages.rtl * {
  text-align: right !important;
}

.departmentWebPages {
  padding: 6vh 7vw 0;
}

.departmentWebPages .service_heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 0% 0px;
}

.departmentWebPages .webDoctorList .swiper-container {
  width: 100%;
  padding: 30px 0px 0px 0px;
}

.departmentWebPages .departmentServicesList {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 0px 0px 0px;
  /* width: 90%; */
  /* margin-left: 3%; */
}

.departmentWebPages
  
  .departmentServicesList
  .departmentServicesListLink {
  width: 25%;
  padding-bottom: 35px;
}

.departmentWebPages
  
  .departmentServicesList
  .departmentServicesListLink
  .departmentServicesListBox {
  display: flex;
  justify-content: flex-end;
}

.departmentWebPages
  
  .departmentServicesList
  .departmentServicesListLink
  .departmentServicesListBox
  .boxCon
  h4 {
  font-size: 12px;
  font-weight: 400;
  color: #222b45;
}

.departmentWebPages
  
  .departmentServicesList
  .departmentServicesListLink
  .departmentServicesListBox
  .boxCon
  p {
  font-size: 10px;
  margin-bottom: 0 !important;
  font-weight: 400;
  color: #8f9bb3;
}

.departmentWebPages
  
  .departmentServicesList
  .departmentServicesListLink
  .departmentServicesListBox
  .boxCon
  span {
  color: #8f9bb3;
  font-weight: 400;
  font-size: 10px;
}

.departmentWebPages
  
  .departmentServicesList
  .departmentServicesListLink
  .departmentServicesListBox
  .boxCon
  .boxHeading {
  direction: rtl;
}

.departmentWebPages .boxCon .boxDetails svg {
  font-size: 16px;
  margin: 0 0 1px 3px;
  fill: var(--primary);
}

.departmentWebPages
  
  .departmentServicesList
  .departmentServicesListLink
  .departmentServicesListBox
  .boxImg {
  width: 22%;
  margin-left: 3%;
}

.departmentWebPages .webBlogList .swiper-container {
  width: 100%;
  padding: 30px 0px 0px 0px;
  /* margin-left: 8%; */
}

.departmentWebPages  .sqaureList {
  padding: 4vh 0vw 0vh 0vw;
}

.departmentWebPages  .sqaureList {
  flex-direction: row-reverse;
  display: flex;
  justify-content: space-between;
  align-items: center; 
}

.departmentWebPages .sqaureList .sqaureListLink {
  width: 32%;
}

.departmentWebPages
  .sqaureList
  .sqaureListLink
  .sqaureListDiv {
  width: 100%;
}

.departmentWebPages
  .sqaureList
  .sqaureListLink
  .sqaureListDiv
  .sqaureListImgDiv {
  background-size: cover;
  width: 100%;
  position: relative;
}

.departmentWebPages
  .sqaureList
  .sqaureListLink
  .sqaureListDiv
  .sqaureListImg {
  object-fit: cover;
  border-radius: 5px;
  /* width: 95%; */
}

.departmentWebPages
  .sqaureList
  .sqaureListLink
  .sqaureListDiv
  .sqaureListTitle {
  position: relative;
  color: white;
  font-weight: 500;
  font-size: 25px;
  bottom: 35px;
  right: 5%;
  margin-bottom: 0;
}

@media only screen and (max-width: 1200px) {
  .departmentWebPages
    .sqaureList
    .sqaureListLink
    .sqaureListDiv
    .sqaureListTitle {
    font-size: 20px;
    bottom: 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .departmentWebPages
    .sqaureList
    .sqaureListLink
    .sqaureListDiv
    .sqaureListTitle {
    font-size: 15px;
    bottom: 25px;
  }
}

@media only screen and (max-width: 800px) {
  .departmentWebPages
    .sqaureList
    .sqaureListLink
    .sqaureListDiv
    .sqaureListTitle {
    font-size: 12px;
    bottom: 20px;
  }
}
