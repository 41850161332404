.hospitalsPage {
  padding: 0px 20px 0;
  margin-bottom: 80px;
}

.hospitalsPage * {
  text-align: right;
}

/* FOR SEARCH SECTION TEXT BOX */
.search_sec .search_area .search_form {
  max-width: 80%;
}

.search_sec .search_area .input_ser {
  text-align: end;
}

.hospitalsPage .staffSquaresDiv {
  background-image: linear-gradient(to right, #fafafa, #f7f7f7, #fafafa);
  padding: 10px 0;
  margin-top: 30px;
}

.hospitalsPage .staffSquaresDiv .hospital_open_round {
  background-color: white;
  flex-direction: row-reverse;
  padding:  10px 10px 10px;
  margin-bottom: 12px;
  border-radius: 5px;
}

/* .hospitalsPage .staffSquaresDiv .hospital_open_round {
	display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 15.5px 15px 15.5px 15px;
    box-shadow: 0px 0px 20px -5px rgb(0 0 0 / 20%);
    margin-bottom: 20px;
    margin-top: 20px;
} */