html {
  --primary: #BF2C7D;
  --secondry: #D591B7;
  --gradient: linear-gradient(to right, #BF2C7D, #D591B7);
  --profileFirstCircle: #D98DB7;
  --profileSecondCircle: #EABCD5;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a {
  text-decoration: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
  /* font-family: "Poppins"; */
  font-family: 'Assistant' !important;

  margin: 0;
}

button {
  background-color: transparent;
  border: 0;
}

.PageDad {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.regularPage {
  padding: 38px 21px 80px;
  max-width: 428px;
  width: 428px;
  /*border: 1px solid gray;*/
}

.headPage {
  padding: 38px 21px 80px;
  max-width: 428px;
  width: 100%;
  height: 100vh;
  /*border: 1px solid gray;*/
}

.variablePage {
  min-height: 95vh;
}

.search_sec {
  padding: 0 21px;
}

.search_sec .search_area {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.search_sec .search_area .filter_icon {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 10px;
  background: var(--primary);
}

.search_sec .search_area .search_form {
  position: relative;
  height: 50px;
  max-width: 75%;
  width: 100%;
}

.search_sec .search_area .input_ser {
  /* border: 1px solid #EAEBEC; */
  height: 50px;
  font-size: 14px;
  color: #77838f;
  border-radius: 6px;
  padding: 0 10px 0 30px;
  width: 100%;
}

.loginFormFeedback {
  color: red !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  margin-bottom: 0;
}

.signUpFormFeedback {
  color: red !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  margin-bottom: 0;
}

.webLoginFormFeedback {
  color: red !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: 0;
}

.websignUpFormFeedback {
  color: red !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: 0;
}

.error {
  color: red !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.invalid-error {
  border: 1px solid red !important;
}

.btn-check:active+.btn-secondary,
.btn-check:checked+.btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show>.btn-secondary.dropdown-toggle {
  background-color: var(--primary) !important;
  color: #fff !important;
}

.dropdown-toggle::after {
  float: left;
  margin: 10px auto !important;
}

.searchPagesContainer .searchPagesContainerBtn {
  text-align: right !important;
}

.dropdown-menu {
  text-align: right !important;
  right: 0 !important;
}

.mv {
  z-index: 5;
  position: sticky;
  top: 0;
}