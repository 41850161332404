.doctorsPage {
  padding: 0px 21px 0;
  margin-bottom: 100px;
}

.doctorsPage * {
  text-align: right;
}

.SearchDiv {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 0 26px;
  width: 100%;
}

.searchStar {
  width: 50px;
}

.SearchImg {
  width: 16px;
  height: 16px;
}

.Search {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border: 1px solid gray;
  border-radius: 12px;
  padding: 0 15px;
  width: 80%;
}

.SearchInput {
  font-size: 14px;
  font-family: "Poppins";
  padding: 10px;
  width: 100%;
}

.SearchInput::placeholder {
  color: #77838f;
}

.doctorsPage .SearchDiv .filter_icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  background: var(--primary);
}
