.departmentsSearchPages {
  padding: 6vh 7vw 0;
}

.departmentsSearchPages .departmentListContainer {
  width: 85%;
  max-width: 100%;
}

.departmentsSearchPages .departmentListContainer .departmentDetail {
  background-color: #f4f4f4;
}

.departmentsSearchPages .departmentDetail .departmentDetailBtn {
  font-size: 18px;
  font-weight: 400;
  background-color: var(--primary);
  border-color: var(--primary);
  width: 30%;
}

.departmentsSearchPages .departmentDetail .departmentDetailBtn .row .departmentDetailArrowIcon svg {
  height: 18px;
  width: 18px;
  position: absolute;
  margin-top: 5px;
}

.departmentsSearchPages .departmentDetail .departmentDetailBtn .row .departmentDetailArrowIcon svg > path {
  color: white;
}

.departmentsSearchPages .departmentDetail .departmentDetailBtn .row .departmentDetailBtnText {
  color: white;
}

.departmentsSearchPages .departmentDetail .departmentDetailTitle {
  font-size: 18px;
  font-weight: 400;
  color: #222b45;
  margin: 0 auto;
  margin-right: 10px;
}

.departmentsSearchPages
  .departmentSearchPagesMoreBtnDiv
  .departmentSearchPagesMoreBtn {
  background-color: var(--primary);
  border-color: var(--primary);
  color: white;
  width: 20%;
  border-radius: 0px;
  direction: rtl;
}

.departmentsSearchPages
  .departmentSearchPagesMoreBtnDiv
  .departmentSearchPagesMoreBtn:hover,
.departmentsSearchPages
  .departmentSearchPagesMoreBtnDiv
  .departmentSearchPagesMoreBtn:active {
  background-color: var(--primary);
}

.departmentsSearchPages button:focus,
.departmentsSearchPages button:active {
  outline: none !important;
  box-shadow: none !important;
}
