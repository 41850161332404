.analyzesSquare {
    background-color: white;
    margin-bottom: 26px;
    height: 131px;
}

.imgAndText {
	position: relative;
}

.analyzesSquareTextImg {
    width: 63px;
    height: 36px;
    position: absolute;
    top: -23px;
    right: -9px;
}