.webHospitalList .hospital_list_box_area {
	display: flex;
	flex-direction: row-reverse;
	flex-wrap: wrap;
	justify-content: space-between;
}

.webHospitalList .hospital_list_box_link {
	width: 25%;
	padding-bottom: 35px;
}

.webHospitalList .hospital_list_box {
	display: flex;
	justify-content: flex-end;
}

.webHospitalList .box_con h4 {
	/* font-size: calc(10px + 0.26vw); */
}

.webHospitalList .box_con p {
	/* font-size: calc(10px + 0.15vw); */
}

.webHospitalList .box_con span {
	font-size: 12px;
	color: #8F9BB3;
	font-weight: 400;
	/* font-size: calc(10px + 0.15vw); */
}

.webHospitalList .box_con .box_details svg {
	font-size: 16px;
    margin: 0 0 1px 3px;
    fill: var(--primary);
}

.box_heading  {
	direction: rtl;
}

.webHospitalList .box_img {
	width: 22%;
	margin-left: 3%;
}