.homeWebPage {
	padding: 6vh 7vw 0;
}

.homeWebPage .appointmentSquare {
	background-image: url(../../../assets/images/web/home/appointment.jpg);
    height: 29vh;
    max-height: 336px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    border-radius: 10px;
    margin-bottom: 6vh;
}

.homeWebPage .appointmentSquareH {
	font-size: 79px;
	font-weight: 600;
	color: white;
}

.homeWebPage .service_heading {
    display: flex;
    justify-content: space-between;
    padding: 0 0 2vh;
}

.homeWebPage .service_heading a {
    /* width: 15%; */
}

.homeWebPage .service_heading a img {
    width: 75%;
}

.homeWebPage .service_heading h3 {
    font-size: 22px;
    font-weight: 700;
    color: #77838F;
    margin-bottom: 0;
    direction: rtl;
}

.homeWebPage .myHealth .myHealthArr {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-bottom: 8vh;
}

.homeWebPage .myHealth .myHealthArr .my_health_box_img {
	width: 32%;
	position: relative;
}

.homeWebPage .myHealth .myHealthArr .my_health_box_img .my_health_boxH {
    position: absolute;
    bottom: 13%;
    right: 4%;
    color: white;
    font-size: 40px;
}