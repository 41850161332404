.blogPage * {
  text-align: right;
}

.blogPage {
  padding: 0 20px;
  margin-bottom: 100px;
}

.blogPage .openSquareTextH {
  font-size: 17px;
  line-height: 1.17;
}

@media all and (max-width: 500px) {
  .blogPage .openSquareTextH {
    font-size: 14px;
  }
}
