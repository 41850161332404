/* Create the Default Paragraphs */
.home_pages.rtl * {
  text-align: right !important;
}

/* .home_pages .d-flex{display: flex;} */

.home_pages {
  height: 100%;
  max-width: 428px;
  width: 100%;
}

.doctors_sec .container .doctors_area .doctor_heading a {
  float: left;
  word-wrap: break-word;
}

.home_pages .doctor_box_area {
  margin-bottom: 100px;
}

.home_pages .my_health_sec {
  padding: 20px 10px 10px;
}

.home_pages .my_health_sec .my_health_heading a {
  font-size: 15px;
  color: var(--primary) !important;
  font-weight: 400;
  float: left;
}

.home_pages .my_health_sec .my_health_heading a img {
  margin-right: 3px;
  transition: all 0.5s ease;
  max-width: 11px;
}

.home_pages .my_health_sec .my_health_heading h3 {
  font-size: 17px;
  font-weight: 400;
}

.home_pages
  .my_health_sec
  .my_health_list
  .my_health_box
  .my_health_box_img {
    height: 110px;
  }

.home_pages
  .my_health_sec
  .my_health_list
  .my_health_box
  .my_health_box_img
  img {
  height: 110px;
  width: 120px;
  object-fit: cover;
  max-width: 100%;
  border-radius: 10px;
}

.home_pages
  .my_health_sec
  .my_health_list
  .my_health_box
  .my_health_box_img
  h6 {
    margin-top: -34px;
    color: white;
    font-size: 15px;
    margin-right: 5px;
    font-weight: 500;
}

.home_pages .container .btn_area {
  display: flex;
  margin-top: 5px;
}
.home_pages .container .btn_area .go_btn {
  width: 100%;
  margin: 0 auto;
  color: var(--White-color);
  background: rgb(255, 255, 255);
  background-image: linear-gradient(to right, var(--primary) , var(--secondry));
  border-radius: 15px;
  display: inline-block;
  padding: 15px 0;
  font-size: 17px;
  font-weight: 700;
  height: 90px;
}

.home_pages .container .btn_area .go_btn .btn_heading {
  margin-right: 5px;
}

.home_pages .container .btn_area .go_btn .btn_heading svg {
  transition: all 0.5s ease;
  background: white;
  fill: var(--primary);
  font-size: 46px;
  padding: 8px;
  border-radius: 10px;
}

.home_pages .category_area {
  padding: 20px 10px 10px 10px;
}

.home_pages .category_area .image_title {
  position: absolute;
  right: 15px;
  top: 15px;
  text-transform: capitalize;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 1px;
}

.home_pages .category_area .image_des_h6_1 {
  position: absolute;
  right: 15px;
  bottom: 0;
  text-transform: capitalize;
  color: #ffffff;
  font-size: 22px;
  letter-spacing: 1px;
  width: 80%;
}

.home_pages .category_area .image_des_h6_2 {
  position: absolute;
  right: 15px;
  bottom: 3%;
  text-transform: capitalize;
  color: #ffffff;
  font-size: 22px;
  letter-spacing: 1px;
}

.home_pages .category_area .right_arrow_img {
  position: absolute;
  left: 20px;
  bottom: 25px;
  width: 7px;
  height: 15px;
}

.home_pages .go_btn .btn_heading .text_area {
  float: right;
  padding-right: 5px;
}
.home_pages .go_btn .btn_heading .text_area p {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin: 0
}
.home_pages .go_btn .btn_heading .text_area h3 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}

.home_pages .go_btn .btn_heading .add_image {
  float: left;
  padding-left: 15px;
  padding-top: 7px;
}

.home_pages .my_club_pregnant_sec {
  padding: 20px 18px 10px;
}

.home_pages .my_club_pregnant_sec .my_club_pregnant_heading a {
  font-size: 15px;
  color: var(--primary) !important;
  font-weight: 400;
  float: left;
}

.home_pages .my_club_pregnant_sec .my_club_pregnant_heading a img {
  margin-right: 3px;
  transition: all 0.5s ease;
  max-width: 11px;
}

.home_pages .my_club_pregnant_sec .my_club_pregnant_heading h3 {
  font-size: 17px;
  font-weight: 400;
}

.home_pages .my_club_pregnant_sec .my_club_div {
  background-color: #F4F4F4;
  border-radius: 10px;
  width: 100%;
  height: 120px;
  padding: 60px;
}

.home_pages .my_club_pregnant_sec .my_club_div .my_club_div_img {
  position: absolute;
  top: 13%;
  bottom: 0;
  height: 90px;
  right: 3%;
  border-radius: 8px;
}

.home_pages .my_club_pregnant_sec .my_club_div .my_club_div_details {
  display: block;
  position: absolute;
  /* left: 23%; */
  top: 30%;
  right: 35%;
  padding-left: 20px;
}

.home_pages .my_club_pregnant_sec .my_club_div .my_club_div_details p {
  font-size: 15px;
  line-height: 1.33;
  font-weight: 400;
  color: #8F9BB3;
}

.home_pages .my_club_pregnant_sec .my_club_div .my_club_div_details h3 {
  font-size: 15px;
  line-height: 1.33;
  font-weight: 400;
  margin-bottom: 0px;
}

.home_pages .my_club_pregnant_sec .my_club_div .right_arrow_img {
  position: absolute;
  left: 3%;
  top: 42%;
  width: 10px;
  height: 20px;
}

.home_pages .our_blog_sec {
  padding: 0px 18px 10px;
}

.home_pages .our_blog_sec .our_blog_heading a {
  font-size: 15px;
  color: var(--primary) !important;
  font-weight: 400;
  float: left;
}

.home_pages .our_blog_sec .our_blog_heading a img {
  margin-right: 3px;
  transition: all 0.5s ease;
  max-width: 11px;
}

.home_pages .our_blog_sec .our_blog_heading h3 {
  font-size: 17px;
  font-weight: 400;
}

.home_pages .our_blog_sec .image_des_1 {
  position: absolute;
  right: 20px;
  bottom: 0;
  text-transform: capitalize;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 1px;
  width: 80%;
}

.home_pages .our_blog_sec .image_des_2 {
  position: absolute;
  right: 20px;
  bottom: 5%;
  text-transform: capitalize;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 1px;
}

.home_pages .our_blog_sec .right_arrow_img {
  position: absolute;
  left: 20px;
  bottom: 25px;
  width: 7px;
  height: 15px;
}

@media only screen and (max-width: 390px) {
  .home_pages .my_club_pregnant_sec .my_club_div .my_club_div_details h3 {
    font-size: 15px;
  }
  .home_pages .my_club_pregnant_sec .my_club_div .my_club_div_details p {
    font-size: 14px;
  }
  .home_pages .our_blog_sec .image_des {
    font-size: 16px;
  }
}

.swiper-container .swiper-wrapper .swiper-slide .category_image{
  width: 100% !important;
}

.home_pages .search_sec .search_area .search_form {
  max-width: 72%;
}
@media all and (max-width: 400px) {
  .home_pages .search_sec .search_area .search_form {
    max-width: 68%;
  } 
}

.home_pages .doubleAroow {
  fill: var(--primary);
  margin: 0 5px 1px 0;
}