.tabsMenu {
    list-style: disc;
    padding-right: 0;
    list-style: none;
    display: flex;
    width: 100%;
    direction: rtl;
}


.tabsMenu li a {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 16px;
    color: #A5A6A8;
    padding: 6px 10px;
    border-radius: 50px;
    margin-bottom: 10px;
    display: inline-block;
}

.tabsMenu li.active a {
    background: var(--primary);
    color: #ffffff;
    font-size: 17px;
    font-weight: 700;
}

