.SearchDiv {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	padding: 0 0 26px;
	width: 100%
}

.SearchDiv .searchStar {
    fill: white;
    background: var(--primary);
    padding: 10px;
    border-radius: 10px;
    font-size: 50px;
}

.Search svg {
    fill: var(--primary);
}

.Search {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border: 1px solid gray;
    border-radius: 12px;
    /* width: 320px; */
    padding: 0 15px;
    width: 80%;
}

.SearchInput {
	font-size: 14px;
    font-family: 'Poppins';
    padding:10px;
    width: 100%;
}

.SearchInput::placeholder {
    color: #77838F;
}