.staffPage {
	padding: 0 20.5px 0;
	margin-bottom: 100px
}

.staffPage * {
	text-align: right;
}

.staffSquaresDiv {
	background-image: linear-gradient(to right, #FAFAFA , #F7F7F7, #FAFAFA);
}

