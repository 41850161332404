.accountPages {
  display: flex;
  justify-content: center;
  padding: 6vh 7vw 0;
}

.accountPages .webAccountPage {
  width: 50%;
  max-width: 50%;
}

.accountPages .webAccountPage .accountMain {
  display: flex;
  flex-direction: row-reverse;
  text-align: right;
  padding-bottom: 15px;
}

.accountPages .webAccountPage .accountMain .accountMainImgDiv {
  padding-left: 20px;
}

.accountPages .webAccountPage .accountMain .accountMainImgDiv .roundBox {
  width: 102px;
  height: 102px;
  padding: 6px;
  background: #bb769c;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  box-shadow: 0 3px 6px #00000029;
  position: relative;
  z-index: 1;
}

.accountPages
  .webAccountPage
  .accountMain
  .accountMainImgDiv
  .roundBox
  .innerRoundBox {
  width: 90px;
  height: 90px;
  padding: 6px;
  background: var(--primary);
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  z-index: 1;
}

.accountPages
  .webAccountPage
  .accountMain
  .accountMainImgDiv
  .roundBox
  .innerRoundBox
  .accountMainImg {
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.accountPages .webAccountPage .accountMain .accountMainText {
  padding-left: 50px;
}

.accountPages .webAccountPage .accountMain .accountMainText .accountMainTextH {
  padding-top: 15px;
  color: var(--primary);
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 0px !important;
}

.accountPages
  .webAccountPage
  .accountMain
  .accountMainText
  .accountPages
  .accountMainTextP {
  color: var(--secondry);
  font-size: 18px;
  line-height: 25px;
}

.accountPages
  .webAccountPage
  .accountMain
  .accountMainText
  .accountMainTextImg {
  width: 100%;
  padding-bottom: 8px;
  padding-top: 5px;
}

.accountPages
  .webAccountPage
  .accountMain
  .accountMainText
  .accountMainTextDays {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.accountPages
  .webAccountPage
  .accountMain
  .accountMainText
  .accountMainTextDays
  .daysBeen {
  background-color: var(--secondry);
  color: white;
}

.accountPages
  .webAccountPage
  .accountMain
  .accountMainText
  .accountMainTextDays
  .daysLeft {
  border: 1px solid #e2e2e2;
  color: #707070;
}

.accountPages .webAccountPage .infoList {
  background-image: linear-gradient(to right, #fafafa, #f7f7f7, #fafafa);
}

.accountPages .webAccountPage .infoList .infoSquare {
  background-color: white;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  padding: 23px 27px 24px;
  margin-bottom: 12px;
  border-radius: 5px;
}

.accountPages .webAccountPage .infoList .infoSquare .infoSquareImgDiv {
  padding-left: 33px;
  display: flex;
  align-items: center;
  height: 56px;
}

.accountPages
  .webAccountPage
  .infoList
  .infoSquare
  .infoSquareImgDiv
  .infoSquareImg {
  width: 46px;
}

.accountPages .webAccountPage .infoList .infoSquare .infoSquareTextDiv {
  display: flex;
  flex-direction: column;
}

.accountPages
  .webAccountPage
  .infoList
  .infoSquare
  .infoSquareTextDiv
  .infoSquareH {
  font-size: 20px;
  font-weight: 300;
  text-align: right;
  margin-bottom: 0px !important;
}

.accountPages
  .webAccountPage
  .infoList
  .infoSquare
  .infoSquareTextDiv
  .infoSquareP {
  background-color: transparent;
  border: 0;
  color: #8f9bb3;
  text-align: right;
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
}

.accountPages .webAccountPage .infoList .infoSquare .infoSquareEdit {
  background-color: transparent;
  border: 0;
  color: #8f9bb3 !important;
  text-align: left;
  font-size: 21px;
  font-weight: 300;
  line-height: 37px;
  margin-right: auto;
}

.accountPages .webAccountPage .infoList .infoSquare .infoSquareEdit .linkEdit {
  color: #8f9bb3 !important;
}

@media all and (max-width: 500px) {
  .accountPages
    .webAccountPage
    .infoList
    .infoSquare
    .infoSquareTextDiv
    .infoSquareH {
    font-size: 16px;
  }

  .accountPages
    .webAccountPage
    .infoList
    .infoSquare
    .infoSquareTextDiv
    .infoSquareP {
    font-size: 14px;
  }

  .accountPages .webAccountPage .infoList .infoSquare .infoSquareImgDiv {
    padding-left: 23px;
    height: 46px;
  }

  .accountPages
    .webAccountPage
    .infoList
    .infoSquare
    .infoSquareImgDiv
    .infoSquareImg {
    width: 40px;
  }
}
