.product_details {
  padding: 6vh 7vw 0;
}

.product_details .main_content .product_card .product_box_img img {
  max-width: 435px;
  width: 400%;
}

.product_details .main_content .product_card .product_box_img .time_dt {
  position: absolute;
  width: 78.3px;
  height: 44.57px;
  line-height: 35px;
  border-radius: 17px;
  left: 1%;
  top: 4%;
  background: var(--primary);
}

.product_details
  .main_content
  .product_card
  .product_box_img
  .time_dt
  .time_dt_p {
  color: white;
  padding: 6px 12px 5px 10px;
  font-size: 20px;
  font-weight: 700;
}
.product_details .main_content .product_description {
  text-align: right !important;
}
.product_details .main_content .product_description .description_title {
  font-family: "Poppins";
  font-size: 22px;
  color: #686464;
}

.product_details .main_content .product_description .description_text {
  font-family: "Poppins";
  font-size: 17px;
  direction: rtl;
  color: #77838F;
}

.product_details .main_content .rating_div {
  text-align: right !important;
}

.product_details
  .main_content
  .rating_div
  .rating
  .productdetail_rating
  h5 {
    color: #252525;
}

.product_details
  .main_content
  .rating_div
  .rating
  .productdetail_rating
  .productdetail_star_list {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 5px;
}

.product_details
  .main_content
  .rating_div
  .rating
  .productdetail_rating
  .fa-star {
  margin: 1px;
  font-size: 20px;
  fill: var(--primary);
}

.product_details
  .main_content
  .rating_div
  .rating
  .productdetail_rating{
    clear:both;
}

.product_details
  .main_content
  .rating_div
  .rating
  .productdetail_rating
  .checked {
  color: var(--primary);
}

.product_details
  .main_content
  .rating_div
  .rating
  .productdetail_rating
  .ratingimg {
  margin-right: 10px;
  height: 11.5px;
}
.product_details .main_content .rating_div .rating{
  margin: 0 20px;
}
.product_details .main_content .rating_div .rating .productdetail_card {
  
}

.product_details .main_content .rating_div .rating .productdetail_card_div {
  text-align: right !important;
  float: right;
  margin: 15px 0px;
  box-shadow: 0px 0px 5px 0px #00000042;
  background: var(--White-color);
  border-radius: 10px;
  width: 87px;
  height: 65px;
}

.product_details
  .main_content
  .rating_div
  .rating
  .productdetail_card_div
  .productdetail_card_text {
  font-size: 32px;
  font-weight: 500;
  color: var(--primary);
  text-align: center !important;
  margin-bottom: 0;
}

.product_details
  .main_content
  .rating_div
  .rating
  .productdetail_card_div
  .productdetail_card_subtext {
  font-size: 9px;
  color: var(--secondry);
  font-weight: 400;
  text-align: center !important;
}
.product_details .main_content .rating_div {
  box-shadow: 0px 0px 5px 0px #00000042;
  background: var(--White-color);
  border-radius: 10px;
}
.product_details
  .main_content
  .rating_div
  .rating
  .productdetail_rating
  .productdetail_star_list
  .productdetail_rating_p {
  font-size: 11px;
  font-weight: 500;
  color: #222b45;
  text-align: left !important;
  direction: rtl;
  padding: 10px 10px 0px 0px;
}
.product_details .main_content .product_image .product_header {
  color: #1E2022;
  font-family: "Poppins";
  font-size: 33px;
  padding: 10px 0px;
  font-weight: 700;
  text-align: right !important;
}
.product_details .main_content .product_image .product_iamge_text {
  color: #77838F;
  font-family: "Poppins";
  font-size: 26px;
  padding: 10px 0px;
  text-align: right !important;
}
.product_details .main_content .product_box_btn .product_btn_area {
  display: flex;
  justify-content: end;
  align-items: center;
}
.product_details .main_content .product_box_btn .product_btn_area .product_btn_area_heart {
  font-size: 64px;
  padding: 10px;
  background: #E5E5E5;
  fill: var(--primary);
  border-radius: 10px;
  margin: 5px;
}
.product_details .main_content .product_box_btn .product_buy_now_btn {
  width: 35%;
  margin: 0px auto;
  color: var(--White-color);
  background: var(--primary);
  border-radius: 8px;
  display: inline-block;
  padding: 10px 0;
  font-size: 26px;
  font-weight: 700;
  margin: 5px;
  text-align: center;
}

/* .product_details .main_content .product_box_btn .btn_line {
    display: inline-block;
  }
   */
.product_details .main_content .product_box_btn .product_add_to_card_btn {
  background-color: var(--secondry);
}
.product_details .main_content .product_card .product_price {
  padding: 28px 0;
  display: flex;
  justify-content: flex-end;
}
.product_details
  .main_content
  .product_card
  .product_price
  .product_price_number {
  color: var(--primary);
  font-size: 35px;
  font-family: "Poppins";
  font-weight: 700;
}
.product_details
  .main_content
  .product_card
  .product_price
  .product_price_text {
  color: #1E2022;
  font-size: 26px;
  font-family: "Poppins";
  font-weight: 500;
  padding-left: 15%;
  width: 72%;
}
.product_details .main_content .product_card .product_category p {
  direction: rtl;
  color: #2C2B2B;
}
.product_details .main_content .product_card .likes {
  direction: rtl;
  padding: 5% 5px 0 12px;
}
.product_details .main_content .product_card .likes span {
  color: #858997
}
.product_details .main_content .product_card .likes svg {
  font-size: 39px;
  fill: var(--secondry)
}
.product_details
  .container
  .main_content
  .product_card
  .row
  .product_card_row_col_detail {
  text-align: right !important;
}
.product_details .main_content .product_box_btn {
  /*text-align: center !important;*/
  /*width: 100%;*/
  margin-top: 15%;
}

@media (min-width: 990px) and (max-width: 1200px) {
  .product_details {
    padding: 60px 20px 0px 0px;
  }
  .product_details .main_content .product_card .product_box_img img {
    max-width: 435px;
    width: 400%;
    text-align: center;
  }
  .product_details .main_content .product_card .product_box_img {
    text-align: center;
  }
  .product_details .main_content .product_card .product_box_img .time_dt {
    position: absolute;
    width: 62.3px;
    height: 40.57px;
    line-height: 30px;
    border-radius: 17px;
    left: 2%;
    top: 4%;
    background: var(--primary);
  }
  .product_details
    .main_content
    .product_card
    .product_price
    .product_price_text {
    font-size: 16px;
  }
  .product_details
    .main_content
    .product_card
    .product_price
    .product_price_number {
    color: var(--primary);
    font-size: 26px;
    font-family: "Poppins";
    font-weight: 700;
  }

  .product_details
    .main_content
    .product_card
    .product_box_img
    .time_dt
    .time_dt_p {
    color: white;
    padding: 6px 12px 5px 10px;
    font-size: 18px;
    font-weight: 700;
  }
  .product_details .main_content .product_box_btn .product_buy_now_btn {
    width: 35%;
    margin: 0px auto;
    color: var(--White-color);
    background: var(--primary);
    border-radius: 8px;
    display: inline-block;
    padding: 10px 0;
    font-size: 17px;
    font-weight: 700;
    margin: 5px;
  }
  .product_details .main_content .product_box_btn .product_add_to_card_btn {
    background: var(--secondry);
  }
}

@media (min-width: 992px) {
  .product_details .container .main_content .product_card .row .col-lg-4 {
    flex: 0 0 auto !important;
    width: 40% !important;
  }
  .product_details .container .main_content .product_card .row .col-lg-8 {
    flex: 0 0 auto !important;
    width: 60% !important;
  }
}


@media (min-width: 1200px) and (max-width: 1310px) {
  .product_details
    .main_content
    .product_card
    .product_price
    .product_price_text {
      font-size: 26px;
      font-family: "Poppins";
      font-weight: 500;
      padding-left: 10%;
      width: 72%;
  }
}

.product_details .starsAndBar {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.product_details .ratingimg {
  margin-right: 10px;
  height: 10px;
  width: 64%;
  background: var(--primary);
  display: inline-block;
  border-radius: 10px;
}